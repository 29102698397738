import React, {useEffect, useState} from "react";
import api from "./api";
import HoldingItem from "./HoldingItem";
import HoldingDivider from "./HoldingDivider";
import LoadingSpinner from "./LoadingSpinner";
import "./HoldingList.css";
import "./Common.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {LINKNONUMBER, varietalList} from "./Common";
import {logPageView} from "./analytics";
import Select from "react-select";
import firebase from "firebase/app";
import firebaseApp from "./firebaseApp";
import "firebase/auth";


function SearchDialog(props) {

    const [varietal, setVarietal] = useState(null);
    const [wineType, setWineType] = useState(null);


    function onSaveClick() {
        if (props.searchType==="varietal") {
            props.onSaveClick(varietal.value)
        } else {
            props.onSaveClick(wineType)
        }
    }

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="SearchDialog"
             onClick={() => props.onOutsideClick()}>
            <div className="card" onClick={doNothing}>
                <div className="form">
                    {(props.searchType === "varietal") && (
                        <div>
                            <h4>Narrow holdings to a varietal</h4>
                            <div className="label">Varietal</div>
                            <Select
                            onChange={setVarietal}
                            options={varietalList.map(t=>({value: t, label: t}))}
                            placeholder="None Selected"
                            />
                        </div>
                    )}

                    {(props.searchType === "wineType") && (
                        <div>
                            <div className="label">Would you like to narrow to red or white wines?</div>
                            <div className="toggle-buttons">
                                <button type="button"
                                        className={`btn ${wineType === "Red" ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                        onClick={() => setWineType("Red")}>
                                    Red
                                </button>
                                <button type="button"
                                        className={`btn ${wineType === "White" ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                        onClick={() => setWineType("White")}>
                                    White
                                </button>
                                <button type="button"
                                        className={`btn ${wineType === "Rose" ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                        onClick={() => setWineType("Rose")}>
                                    Rose
                                </button>
                            </div>
                        </div>
                    )}

                <div className="button-container">
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => onSaveClick()}>
                        Search
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
}
SearchDialog.propTypes = {
    searchType: PropTypes.string.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
}

let holdingList;

function HoldingList() {

    // let {userLink} = useParams();
    const [holdings, setHoldings] = useState(null);
    const [filteredHoldings, setFilteredHoldings] = useState(null);
    const [searchedFilteredHoldings, setSearchedFilteredHoldings] = useState(null);
    const taxRate = 1.085;

    const [showSearch, setShowSearch] = useState({displaySearch: false, searchType: "varietal"});
    const [searchVarietal, setSearchVarietal] = useState(null);
    const [searchWineType, setSearchWineType] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [wrappedUser, setUser] = useState(null);
    const [showValueDefine, setShowValueDefine] = useState(false);

    firebaseApp();

    useEffect(() => {
        logPageView("holding_list");
    },[]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            if (user === null) {
                setUser({ user: null })
            } else {
                setUser({ user });
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    console.log("firebase user is",firebase.auth().currentUser);
    console.log("holdinglist is ",holdingList);
    // console.log("firebase user id is", firebase.auth().currentUser.uid);

    useEffect(() => {
        if (!holdingList && wrappedUser && wrappedUser.user) {
            console.log("user is ",wrappedUser.user);
            console.log("user username is ", wrappedUser.user.userName);
            // const unsubscribe = api().collection("holdings")
            //     // .doc(userLink)
            //     .doc(user.uid)
            //     .collection('userHoldings')
            //     .orderBy("wineNumber", "asc")
            //     .onSnapshot(querySnap => {
            //         if (querySnap.empty) {
            //             setHoldings([]);
            //             setFilteredHoldings([]);
            //             console.log("hit db in holdinglist 1");
            //         } else {
            //             console.log("hit db in holdinglist 2");
            //             let tempHoldings = querySnap.docs.map(doc => doc.data());
            //             setHoldings(tempHoldings);
            //             setFilteredHoldings(tempHoldings);
            //         }
            //     }, error => {
            //         console.log("hit db in holdinglist 3");
            //         console.error(`Error loading holdings: ${error}`);
            //     })
            const unsubscribe = api().collection("users")
                // .doc(userLink)
                .doc(wrappedUser.user.uid)
                .collection('holdings')
                .orderBy("wineNumber", "asc")
                .onSnapshot(querySnap => {
                    if (querySnap.empty) {
                        setHoldings([]);
                        setFilteredHoldings([]);
                        console.log("hit db in holdinglist 1");
                    } else {
                        console.log("hit db in holdinglist 2");
                        holdingList = querySnap.docs.map(doc => doc.data());
                        setHoldings(holdingList);
                        setFilteredHoldings(holdingList);
                    }
                }, error => {
                    console.log("hit db in holdinglist 3");
                    console.error(`Error loading holdings: ${error}`);
                })

            return () => {
                unsubscribe()
            }
        } else {
            setHoldings(holdingList);
            setFilteredHoldings(holdingList);
        }
    },[wrappedUser]);

    function getHoldingItems(holdingsToShow) {
        let finalList = holdingsToShow;
        if (sortOrder) {
            console.log("got into sortorder if in getholdingitems");
            finalList = sortList(holdingsToShow)
            console.log("got past sortList command in sortorder if ")

        }
        let allItems;
        const holdingItemList = finalList.filter(it => it.numBottles != 0).map(holding =>
            <HoldingItem
                key={holding.holdingId}
                holding={holding}
                userId={wrappedUser.user.uid}
            />)
        if (holdingItemList.length < finalList.length) {
            const holdingDivider = <HoldingDivider/>;
            const consumedItemList = finalList.filter(it => it.numBottles === 0).map(holding =>
                <HoldingItem
                    key={holding.holdingId}
                    holding={holding}
                    userId={wrappedUser.user.uid}
                />)
            return [...holdingItemList,holdingDivider,...consumedItemList]
        } else {
            return  holdingItemList
        }
        // return finalList.map(holding =>
        //     <HoldingItem
        //         key={holding.holdingId}
        //         holding={holding}
        //         userId={wrappedUser.user.uid}
        //     />);
    }

    //not using placeholder for no exchanges yet bc I will seed it with wines]

    function getCurrentHoldingList() {
        if (searchWineType || searchVarietal) {
            return filteredHoldings;
        } else {
            return holdings;
        }
    }

    function getTotUniqueOgsPurchased() {
        if (searchVarietal || searchWineType) {
            return(filteredHoldings.length)
        } else {
            return(holdings.length)
        }
    }

    function getTotalPurchasedBottles() {
        return getTotalConsumedBottles() + getTotalCellarBottles()
    }

    function getTotalPurchasedValue() {
        let purchasedValue = getTotalConsumedValue() + getTotalCellarValue();
        return  parseFloat(purchasedValue.toFixed(2));
    }

    function getNumOgsCellar() {
        const currentHoldings = getCurrentHoldingList();
        return currentHoldings.filter(holding => holding.numBottles >0).length;
    }

    function getTotalCellarBottles() {
        const currentHoldings = getCurrentHoldingList();

        let botTotal = currentHoldings.reduce(function(prev, cur) {
            return prev + cur.numBottles;
        }, 0);
        if (!botTotal) {
            return 0;
        } else {
            return botTotal;
        }
    }

    function getTotalCellarValue() {
        const currentHoldings = getCurrentHoldingList();
        let values = currentHoldings.map(holding => (parseInt(holding.numBottles)*((holding.casePrice*taxRate)+29)/12));
        let botTotal = values.reduce(function(prev, cur) {
            return parseFloat(prev + cur);
        }, 0);
        if (!botTotal) {
            return 0;
        } else {
            return parseFloat(botTotal.toFixed(2));
        }
    }

    function getNumOgsConsumed() {
        const currentHoldings = getCurrentHoldingList();
        const filteredHoldings = currentHoldings.filter(holding => holding.numConsumed);
        return filteredHoldings.filter(holding => holding.numConsumed >0).length;
    }

    function getTotalConsumedBottles() {
        const currentHoldings = getCurrentHoldingList();
        const filteredHoldings = currentHoldings.filter(holding => holding.numConsumed);
        let botTotal = filteredHoldings.reduce(function(prev, cur) {
            return prev + cur.numConsumed;
        }, 0);
        if (!botTotal) {
            return 0;
        } else {
            return botTotal;
        }
    }

    function getTotalConsumedValue() {
        const currentHoldings = getCurrentHoldingList();
        const filteredHoldings = currentHoldings.filter(holding => holding.numConsumed);
        let values = filteredHoldings.map(holding => (parseInt(holding.numConsumed)*((holding.casePrice*taxRate)+29)/12));
        let botTotal = values.reduce(function(prev, cur) {
            return parseFloat(prev + cur);
        }, 0);
        if (!botTotal) {
            return 0;
        } else {
            return parseFloat(botTotal.toFixed(2));
        }
    }

    //right now have it set up so can search filtered list (holdings filtered by category, etc. but not sure
    //that is necessary - could do either or - now if in category and search for one wine still shows category totals
    function handleSearch(searchTerm) {
        console.log("search term type is", typeof searchTerm);
        console.log("search term is", searchTerm);
        if (searchTerm == "") {
            setSearchedFilteredHoldings(null);
        } else {
            const searchedHoldings = holdings.filter((holding)=> (parseInt(holding.wineNumber) == parseInt(searchTerm)));
            setSearchedFilteredHoldings(searchedHoldings);
        }
    }

    function onSaveClick(searchTerm) {
        let tempFilteredHoldings;
        if (showSearch.searchType === "wineType") {
            console.log("got in winetype if");
            setSearchWineType(searchTerm);
            tempFilteredHoldings = holdings.filter(holding => holding.type === searchTerm);
        } else {
            console.log("got in varietal if");
            setSearchVarietal(searchTerm);
            tempFilteredHoldings = holdings.filter(holding => holding.varietal === searchTerm);
        }

        setShowSearch({displaySearch: false, searchType: "varietal"});
        console.log("searchterm is " + searchTerm);
        console.log("searchVarietal is " + searchVarietal);
        console.log("searchWineType is " + searchWineType);
        console.log("holdings list"+ holdings);
        holdings.forEach(holding => console.log("varietal"+ holding.varietal + " type "+holding.type));
        console.log("temp filtered holdings"+tempFilteredHoldings);
        setFilteredHoldings(tempFilteredHoldings);
    }

    function returnToFullList() {
        setSearchVarietal(null);
        setSearchWineType(null);
        setFilteredHoldings(holdings);
    }

    function sortList(listToSort) {
        let tempList =[...listToSort];
        console.log("got in sort list");
        return tempList.sort(function(a,b) {
            let holdingValA;
            let holdingValB;
            switch(sortOrder) {
                case "cellar":
                    holdingValA = a.cellarLocation?.toUpperCase();
                    holdingValB = b.cellarLocation?.toUpperCase();
                    break;
                case "holdings":
                    holdingValA = parseInt(a.numBottles);
                    holdingValB = parseInt(b.numBottles);
                    break;
                case "lot":
                    holdingValA = parseInt(a.wineNumber);
                    holdingValB = parseInt(b.wineNumber);
                    break;
                default:
                    holdingValA = parseInt(a.wineNumber);
                    holdingValB = parseInt(b.wineNumber);
                    break;
            }

            //below is checking for null, and will sort null to be at end of list
            if (!holdingValA  && !holdingValB) {
                return 0
            } else if (!holdingValA) {
                return 1
            } else if (!holdingValB) {
                return -1
            } else {
                if (holdingValA > holdingValB) {
                    return 1;
                }
                if (holdingValA < holdingValB) {
                    return -1;
                }
                return 0;
            }
        })
    }

    function handleSort (sortField) {
        setSortOrder(sortField);
    }

    return (
        <div>
            <Helmet>
                <title>
                    List of All my holdings of de Negoce Wines
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable ist of holdings from my " +
                        "cellar of de Négoce wines "
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            {wrappedUser && !wrappedUser.user && <h3>You need to be logged in to use this page.</h3>}
            {wrappedUser?.user &&
                <div className="HoldingList">
                    <Link to={LINKNONUMBER} className="primary-button">
                        Add a New Holding
                    </Link>
                    <div className="list-container">
                        <h5>My Wines</h5>
                        {(holdings && holdings.length === 0) && <h5>It doesn't look like you've added any wine holdings yet.  You can add holdings
                        either from the button above or from the wine detail page.</h5>}
                        {(holdings && holdings.length >0) && (
                        <div>
                            <div className="card">
                                {/*<div classname="row text-center">*/}
                                <div className="cardcolumn">
                                    <h5>Purchased</h5>
                                    <div className="row text-center">
                                        <h6>Unique OGs:</h6>
                                        <h6>{getTotUniqueOgsPurchased()}</h6>
                                    </div>
                                    <div className="row text-center">
                                        <h6>Total Bottles: </h6>
                                        <h6>{getTotalPurchasedBottles()}</h6>
                                    </div>
                                    <div className="row text-center">
                                        <h6>Total Value: </h6>
                                        <span className="value-define"
                                              onClick={() => setShowValueDefine(oldValue => !oldValue)}>
                                            🔎
                                        </span>
                                        <h6>${getTotalPurchasedValue()}</h6>
                                    </div>
                                </div>
                                <div className="cardcolumn">
                                    <h5>In Cellar</h5>
                                    <div className="row">
                                        <h6>Unique OGs:</h6>
                                        <h6>{getNumOgsCellar()}</h6>
                                    </div>
                                    <div className="row">
                                        <h6>Total Bottles: </h6>
                                        <h6>{getTotalCellarBottles()}</h6>
                                    </div>
                                    <div className="row">
                                        <h6>Total Value: </h6>
                                        <span className="value-define"
                                              onClick={() => setShowValueDefine(oldValue => !oldValue)}>
                                            🔎
                                        </span>
                                        <h6>${getTotalCellarValue()}</h6>
                                    </div>
                                </div>
                                <div className="cardcolumn">
                                    <h5>Consumed</h5>
                                    <div className="row">
                                        <h6>Unique OGs: </h6>
                                        <h6>{getNumOgsConsumed()}</h6>
                                    </div>
                                    <div className="row">
                                        <h6>Total Bottles: </h6>
                                        <h6>{getTotalConsumedBottles()}</h6>
                                    </div>
                                    <div className="row">
                                        <h6>Total Value: </h6>
                                        <span className="value-define"
                                              onClick={() => setShowValueDefine(oldValue => !oldValue)}>
                                            🔎
                                        </span>
                                        <h6>${getTotalConsumedValue()}</h6>
                                    </div>
                                </div>
                            </div>
                            {showValueDefine && <p>The total value and price per bottle of all wines released before 2/8/22 are estimated using
                                tranche 1 pricing, $29 per case shipping, and 8.5% tax rate.  Wines
                                released after 2/9/22 will be calculated based actual tranche 1 shipping for the largest pack available (normally a case)</p>}
                            <div>
                                <button className="primary-button">
                                    <input type="number" placeholder="Search for Lot #" onChange={(event) => handleSearch(event.target.value)} />
                                </button>
                                <button className="primary-button">
                                    {/*type="button"*/}
                                    {/*className="primary-button"*/}
                                    {/*onClick={() => sortHoldingsByColumn("cellarLocation")}*/}
                                    Sort Order
                                    <select name="colSort" id="sort-select" onChange={(event) => handleSort(event.target.value)}>
                                        <option value="lot">Lot Number</option>
                                        <option value="cellar">Cellar Location</option>
                                        <option value="holdings">Number Available</option>
                                    </select>
                                </button>

                                {(!searchVarietal && !searchWineType) && <button
                                    type="button"
                                    className="primary-button"
                                    onClick={() => setShowSearch({displaySearch: true, searchType: "varietal"})}>
                                    Narrow by Varietal
                                </button>}
                                {(!searchVarietal && !searchWineType) && <button
                                    type="button"
                                    className="primary-button"
                                    onClick={() => setShowSearch({displaySearch: true, searchType: "wineType"})}>
                                    Narrow by Wine Type
                                </button>}
                                {(searchVarietal || searchWineType) && (<button
                                        type="button"
                                        className="primary-button"
                                        onClick={() => returnToFullList()}>
                                        Return to Full List
                                    </button>
                                )}
                            </div>
                        </div>)}

                        {!holdings && (
                            <LoadingSpinner/>
                        )}
                        {searchedFilteredHoldings && getHoldingItems(searchedFilteredHoldings)}
                        {!searchedFilteredHoldings && filteredHoldings && getHoldingItems(filteredHoldings)}
                        {/*{(holdings && (!searchVarietal && !searchWineType)) && getHoldingItems(holdings)}*/}
                        {/*{(filteredHoldings && (searchVarietal || searchWineType)) && getHoldingItems(filteredHoldings)}*/}
                    </div>
            </div>
            }

            {showSearch.displaySearch && (
                <SearchDialog
                    searchType={showSearch.searchType}
                    onSaveClick={(searchTerm) => onSaveClick(searchTerm)}
                    onOutsideClick={() => setShowSearch({displaySearch:false, searchType:"varietal"})} />
            )}
        </div>
    )
}

export default HoldingList;
