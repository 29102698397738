import React, {useEffect, useState} from "react";
import api from "./api";
import LeaderItem from "./LeaderItem";
import LoadingSpinner from "./LoadingSpinner";
import "./LeaderBoard.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import LeaderCommentFeed from "./LeaderCommentFeed";
import {logPageView} from "./analytics";


function LeaderBoard() {

    const [leaders, setLeaders] = useState(null);
    let groupLeaders = null;
    let soloLeaders = null;

    useEffect(() => {
        logPageView("leader_board");
    },[]);

    useEffect(() => {
        api().collection("leaders")
            .orderBy("casesBought", "desc")
            .get()
            .then(querySnap => {
                if (querySnap.empty) {
                    setLeaders([]);
                    console.log("hit db in leaderboard 1");
                } else {
                    console.log("hit db in leaderboard 2");
                    setLeaders(querySnap.docs.map(doc => {
                        let leader = doc.data();
                        return leader;
                    }));
                }
            })
            .catch(error => {
                console.log("hit db in leaderboard 3");
                console.error(`Error loading leaders: ${error}`);
            })
    },[]);

    function getSoloLeaderItems() {
        soloLeaders = leaders.filter(function(leader) {
            return leader.purchaserType === "Individual";
        });
        console.log("soloLeaders are"+soloLeaders);
        return soloLeaders.map(leader =>
            <LeaderItem key={leader.leaderId} leader={leader}/>)
    }

    function getGroupLeaderItems() {
        groupLeaders = leaders.filter(function(leader) {
            return leader.purchaserType === "Group";
        });
        console.log("groupLeaders are"+soloLeaders);
        return groupLeaders.map(leader =>
            <LeaderItem key={leader.leaderId} leader={leader}/>)
    }

    return (
        <div className="LeaderBoard">
            <Helmet>
                <title>
                    List of de Négoce Purchase Leaders
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable list of people that have " +
                        "purchased the most cases under the de Négoce label."
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <Link to="/submitleader" className="primary-button">
                Add yourself to the leaderboard
            </Link>
            <div className="content-container">
                <div className="solo">
                    <h5>Individual Purchasers</h5>
                    {!leaders && (
                        <LoadingSpinner/>
                    )}
                    {leaders && getSoloLeaderItems()}
                </div>
                <div className="group">
                    <h5>Group Purchasers</h5>
                    {!leaders && (
                        <LoadingSpinner/>
                    )}
                    {leaders && getGroupLeaderItems()}
                </div>
                <div className="comments">
                    <h5>Comments</h5>
                    <LeaderCommentFeed/>
                </div>
            </div>
        </div>
    )
}

export default LeaderBoard;


