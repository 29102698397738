import React, {useEffect, useState} from "react";
import api from "./api";
import EventItem from "./EventItem";
import LoadingSpinner from "./LoadingSpinner";
import "./EventList.css";
import "./Common.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";



function EventList() {

    const [events, setEvents] = useState(null);

    useEffect(() => {
        const unsubscribe = api().collection("events")
            .orderBy("timestamp", "asc")
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setEvents([]);
                    console.log("hit db in eventlist 1");
                } else {
                    console.log("hit db in eventlist 2");
                    setEvents(querySnap.docs.map(doc => {
                        let event = doc.data();
                        return event;
                    }))
                }
            }, error => {
                console.log("hit db in eventlist 3");
                console.error(`Error loading event: ${error}`);
            })

        return () => {
            unsubscribe()
        }
    },[]);

    function getEventItems() {
        return events.map(event =>
            <EventItem key={event.eventId} event={event}/>)
    }

    //not using placeholder for no exchanges yet bc I will seed it with wines

    return (
        <div className="EventList">
            <Helmet>
                <title>
                    List of Possible Events for people to share De Negoce wines
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable ist of events for people to socialize " +
                        "while drinking de Négoce wines "
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <Link to="/addevent" className="primary-button">
                Add a New Event
            </Link>

            <div className="list-container">
                {!events && (
                    <LoadingSpinner/>
                )}
                {events && getEventItems()}
            </div>
        </div>
    )
}

export default EventList;