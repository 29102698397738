

const stateList = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT',
    'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

const varietalList = ['Blend','Cabernet Franc','Cabernet Sauvignon', 'Chardonnay', 'Malbec', 'Meritage','Merlot',
    'Petite Sirah','Pinot Noir','Port','Riesling','Rose','Sauvignon Blanc','Sparkling','Syrah','Tempranillo','Viognier','Zinfandel','Other'];

//Carignan (1) Chianti (1) Grenache (1) Mourvedre (1) Petite Verdot (3) /Pinot Blanc (1) Sangiovese(2)  - changed to other in DB
//Shiraz (2) changed to Syrah
// already on list Port(2) Riesling (1) Viognier(3) Sparkling(2) Tempranillo(3)

const typeList = ['Red','White','Rose'];

const NONUMBER = 12345;
const LINKNONUMBER = "/addholding/12345";

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

export {stateList, varietalList, typeList, NONUMBER, LINKNONUMBER, debounce};