import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "./AddWine.css";
import "./Common.css";
import "./Common.css";
import api from "./api";
import { v4 as uuidv4 } from 'uuid';
import ErrorMessage from "./ErrorMessage";
import LinkOut from "./LinkOut";
import ModalDialog from "./ModalDialog";
import {logPageView} from "./analytics";
import {typeList, varietalList} from "./Common";
import firebase from "firebase/app";

class WineAlreadyExistsError extends Error {
    constructor() {
        super('This wine already exists in the database.');
    }
}

function AddWine() {

    const history = useHistory();
    const [varietal, setVarietal] = useState(null);
    const [wineType, setWineType] = useState(null);
    const [showVarietalInstructions, setShowVarietalInstructions] = useState(null);
    const [showSourceInstructions, setShowSourceInstructions] = useState(null);
    const [
        insufficientInfoError,
        setInsufficientInfoError
    ] = useState(false);
    const [
        errorWineAlreadyExists,
        setErrorWineAlreadyExists
    ] = useState(false);
    const [errorSavingWine, setErrorSavingWine] = useState(false);

    useEffect(() => {
        logPageView("add_wine");
    },[]);

    function openDetailPage(number) {
        history.replace(`/${number}`);
    }

    function submitWine() {
        const wineId = uuidv4();
        const nameTextBox = document.getElementById("wine-name");
        const numberTextBox = document.getElementById("wine-number");
        const descTextBox = document.getElementById("wine-description");
        const priceTextBox = document.getElementById("wine-price");
        const emailTextBox = document.getElementById("wine-email");
        const bottleDateTextBox = document.getElementById("bottle-date");
        const sourceTextBox = document.getElementById("source-guess");
        const reviewLinkTextBox = document.getElementById("review-link");
        const negoceLinkTextBox = document.getElementById("negoce-link");
        const vintageYearTextBox = document.getElementById("wine-vintage");
        const retailPriceTextBox = document.getElementById("retail-price");
        const bottlesPerCaseTextBox = document.getElementById("bottles-case");
        const shippingPerCaseBox = document.getElementById("shipping-cost");

        const submitName = nameTextBox.value;
        const submitNumber = numberTextBox.value ? parseInt(numberTextBox.value) : null;
        const submitDesc = descTextBox.value ? descTextBox.value : null;
        const submitPrice = priceTextBox.value ? parseInt(priceTextBox.value) : null;
        const bottlesPerCase = bottlesPerCaseTextBox.value ? parseInt(bottlesPerCaseTextBox.value) : null;
        const bottlePrice = (submitPrice&&bottlesPerCase)? submitPrice/bottlesPerCase : null;
        const retailPrice =  retailPriceTextBox.value ? parseInt(retailPriceTextBox.value) : null;
        const submitEmailText =  emailTextBox.value ? emailTextBox.value : null;
        const submitBottleDate = bottleDateTextBox.value ? bottleDateTextBox.value : null;
        const submitPossibleSource =  sourceTextBox.value ? [sourceTextBox.value] : null;
        const submitReviewLink = reviewLinkTextBox.value ? reviewLinkTextBox.value : null;
        const submitNegoceLink = negoceLinkTextBox.value ? negoceLinkTextBox.value : null;
        const submitVintageYear = vintageYearTextBox.value ? parseInt(vintageYearTextBox.value) : null;
        const shippingCost = shippingPerCaseBox.value ? parseInt(shippingPerCaseBox.value) : null;

        if (!(submitName && submitNumber && submitDesc && varietal && wineType)) {
            setInsufficientInfoError(true);
            return;
        }

        const newWine = {
            key: wineId,
            wineId: wineId,
            number: submitNumber,
            name: submitName,
            // userId: currentUserId(),
            description: submitDesc,
            casePrice: submitPrice,
            bottlesPerCase: bottlesPerCase,
            bottlePrice: bottlePrice,
            retailPrice: retailPrice,
            emailText: submitEmailText,
            bottleDate: submitBottleDate,
            possibleSource: submitPossibleSource,
            cellartrackerReviewLink: submitReviewLink,
            negoceLink: submitNegoceLink,
            varietal: varietal,
            type: wineType,
            vintage: submitVintageYear,
            timestampAddDate: firebase.firestore.Timestamp.now(),
            lastUpdatedTimestamp: null, //not using yet
            wouldDrink: [],
            qprs: [],
            numRatings: [],
            qprAverage: null,
            numRatingAverage: null,
            drinkBeginningYear: null,
            drinkEndingYear: null,
            bottlesAvailable: 0,
            bottlesWanted: 0,
            shippingCost: shippingCost,
        };

        api().collection('wines')
            .where("number", "==", submitNumber)
            .get()
            .then(querySnap => {
                // If the wine already exists, we don't want to add it again.
                if (!querySnap.empty) {
                    throw new WineAlreadyExistsError();
                }
            })
            .then(() => {
                return api()
                    .collection('wines')
                    .doc(wineId)
                    .set(newWine)
                    .then(() => {
                        //setShowAddedToast(true);
                    });
            })
            .then(() => openDetailPage(submitNumber))
            .catch(error => {
                if (error instanceof WineAlreadyExistsError) {
                    setErrorWineAlreadyExists(true);
                } else {
                    setErrorSavingWine(true);
                }
            })
    }

    function getTypeToSelectFrom() {
        let typeButtons = [];
        typeList.forEach(buttonType => typeButtons.push(
            <button type="button"
                    className={`btn ${wineType === buttonType ? 'btn-info' : 'btn-outline-info'}`}
                    onClick={() => setWineType(buttonType)}>
                {buttonType}
            </button>
        ));
        return typeButtons;

    }

    function getVarietalsToSelectFrom() {
        let varietalButtons = [];
        varietalList.forEach(buttonVarietal => varietalButtons.push(
            <button type="button"
                    className={`btn ${varietal === buttonVarietal ? 'btn-info' : 'btn-outline-info'}`}
                    onClick={() => setVarietal(buttonVarietal)}>
                {buttonVarietal}
            </button>
        ))
        return varietalButtons;

    }

    return (
        <div className="AddWine">
            <h5>Add New Wine</h5>
            <div className="form">
                <h6>
                    <i>Must enter at least name, OG number, description, varietal, and type before submitting</i>
                </h6>
                <div>
                    <div className="row">
                        <div className="label">Name of Wine</div>
                        <input id="wine-name"
                               type="string"
                                //onChange={input => nameAdded(input)}
                               aria-describedby="wine-name"
                               placeholder="Enter full name of wine"/>
                    </div>
                    <div className="row">
                        <div className="label">OG Number</div>
                        <input id="wine-number"
                               type="number"
                            //onChange={input => numberAdded(input)}
                               aria-describedby="wine-number"
                               placeholder="Enter OG number (E.g. '40')"/>
                    </div>
                    <div className="row">
                        <div className="label">Enter Full Description of Wine from de Négoce Page</div>
                        <input id="wine-description"
                               type="string"
                            //onChange={input => descAdded(input)}
                               aria-describedby="wine-description"
                               placeholder="Please copy and paste full description from webpage"/>
                    </div>
                    <div className="row">
                        <div className="label">Enter Full Link to de Négoce Page for Wine</div>
                        <input id="negoce-link"
                               type="string"
                               //onChange={input => {negoceLinkAdded(input)}}
                               aria-describedby="negoce-link"
                               placeholder="Enter link to de Négoce page for this release"/>
                    </div>
                    <div className="row">
                        <div className="label">Cost per Case</div>
                        <input id="wine-price"
                               type="number"
                               //onChange={input => {priceAdded(input)}}
                               aria-describedby="wine-price"
                               placeholder="Enter cost per case (w/out tax or shipping) at first release (e.g. '120')"/>
                    </div>
                    <div className="row">
                        <div className="label">Shipping per Case</div>
                        <input id="shipping-cost"
                               type="number"
                            //onChange={input => {priceAdded(input)}}
                               aria-describedby="shipping-cost"
                               placeholder="Enter shipping (as integer) for largest offered case(normally 19 or free (0) for 12, 10 for 6)"/>
                    </div>
                    <div className="row">
                        <div className="label">Number of Bottles per Case (3, 6, or 12)</div>
                        <input id="bottles-case"
                               type="number"
                            //onChange={input => {priceAdded(input)}}
                               aria-describedby="bottles-case"
                               placeholder="Enter number for largest offered case (normally 12)"/>
                    </div>
                    <div className="row">
                        <div className="label">Retail Price</div>
                        <input id="retail-price"
                               type="number"
                            //onChange={input => {priceAdded(input)}}
                               aria-describedby="retail-price"
                               placeholder="Enter integer close to average if range given"/>
                    </div>
                    <div className="row">
                        <div className="label">Vintage Year</div>
                        <input id="wine-vintage"
                               type="number"
                               //onChange={input => {vintageYearAdded(input)}}
                               aria-describedby="wine-cost"
                               placeholder="Enter vintage year of wine"/>
                    </div>
                    <div className="row">
                        <div className="label">Full Link to Cellartracker Reviews</div>
                        <input id="review-link"
                               type="string"
                               //onChange={input => {reviewLinkAdded(input)}}
                               aria-describedby="review-link"
                               placeholder="Enter link to cellartracker reviews"/>
                    </div>
                    <div className="row">
                        <div className="label">Best Guess for Source (in your estimation of consensus)
                            <span className="value-define"
                                  onClick={() => setShowSourceInstructions(oldValue => !oldValue)}>
                                            🔎
                                        </span></div>
                        {showSourceInstructions && <p>Normally this is discussion on the
                            <LinkOut url="https://www.wineberserkers.com/forum/viewtopic.php?f=1&t=169722" text=" DeNegoce offers thread "></LinkOut>
                            on Wine Berserkers</p>}
                        <input id="source-guess"
                               type="string"
                               //onChange={input => {sourceGuessAdded(input)}}
                               aria-describedby="source-guess"
                               placeholder="Please enter only one guess"/>
                    </div>
                    <div className="row">
                        <div className="label">Date Wine Was Bottled</div>
                        <i>Enter 15th for day of month if not known</i>
                        <input id="bottle-date"
                               type="date"
                               //onChange={input => {dateBottled(input)}}
                               aria-describedby="source-guess"
                               placeholder="Enter date originally bottled in form mm/dd/yyyy"/>
                    </div>
                    <div className="row">
                        <div className="label">Email Text</div>
                        <input id="wine-email"
                               type="string"
                               //onChange={input => {emailTextAdded(input)}}
                               aria-describedby="wine-email"
                               placeholder="OPTIONAL Paste text from email announcement here."/>
                    </div>
                    <div className="row">
                        <div className="label">What is the varietal of the wine?
                            <span className="value-define"
                                  onClick={() => setShowVarietalInstructions(oldValue => !oldValue)}>
                                            🔎
                                        </span></div>
                    </div>
                    {showVarietalInstructions && <p>If the varietal is not shown and it's not really a blend, just choose 'other'.</p>}
                    <div className="multi-select-row">
                        {getVarietalsToSelectFrom()}
                    </div>
                    <div className="row">
                        <div className="label">What is the type of the wine?</div>
                    </div>
                    <div className="multi-select-row">
                        {getTypeToSelectFrom()}
                    </div>
                </div>
                {insufficientInfoError && (
                    <ErrorMessage message={
                        "Please fill out at least the name of the wine, OG "
                        + "number, full description, varietal, and type of wine before submitting."
                    } />
                )}
                <button type="button"
                        className="submit primary-button"
                        onClick={() => submitWine()}>
                    SUBMIT
                </button>
            </div>
            {errorSavingWine && (
                <ModalDialog
                    title="There was an error saving that wine"
                    body={
                        "Looks like there was an issue saving that wine ... "
                        + "Sorry about that! Please make sure you're connected "
                        + "to the Internet. If you are, please get in touch with "
                        + "us and we'll troubleshoot the issue."
                    }
                    buttonText="Ok"
                    buttonAction={() => setErrorSavingWine(false)}
                    outsideClickAction={() => setErrorSavingWine(false)}
                />
            )}
            {errorWineAlreadyExists && (
                <ModalDialog
                    title="That wine is already in our system"
                    body={
                        "It looks like a wine with the OG number you entered "
                        + "already exists in our system. Please go to the wine "
                        + "detail page to view or edit its information."
                    }
                    buttonText="Go to the wine detail page"
                    buttonAction={openDetailPage}
                    outsideClickAction={() => setErrorWineAlreadyExists(false)}
                />
            )}
        </div>
    );

}

export default AddWine;
