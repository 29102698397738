import React, {useEffect, useState, useLayoutEffect} from "react";
import "./WineList.css";
import "./Common.css"
import {debounce} from "./Common";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import {logPageView, logExternalLink} from "./analytics";
import PropTypes from "prop-types";
import ErrorMessage from "./ErrorMessage";
import LinkOut from "./LinkOut";
import WineFeed from "./WineFeed";
import ListCommentFeed from "./ListCommentFeed";
import venmoQRSmall from "./VenmoQRSmall.png"

function AddReviewModalDialog(props) {

    const [reviewType, setReviewType] = useState(null);
    const [noOgError, setNoOgError] = useState(false);

    function onSaveClick() {
        console.log("got into onSaveClick in addreviewdialog");
        const ogNumberInput = document.getElementById("ogNumber");
        const ogNumber = ogNumberInput.value ? parseInt(ogNumberInput.value) : 0;
        console.log("og Number in onSaveclick " + ogNumber);
        if (ogNumber > 0){
            props.onSaveClick(ogNumber, reviewType)
        } else {
            setNoOgError(true);
            return;
        }

    }

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="AddReviewModalDialog"
             onClick={() => props.onOutsideClick()}>
            <div className="card" onClick={doNothing}>
                <h5>Enter the OG Number of the Wine</h5>
                <input
                    id="ogNumber"
                    type="number"
                    min="1"/>
                <h5>Enter Type of Review You'd Like to Leave</h5>
                <div className="row">
                    <div className="toggle-buttons">
                        <button type="button"
                                className={`btn ${reviewType === 0 ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setReviewType(0)}>
                            Ratings Only
                        </button>
                        <button type="button"
                                className={`btn ${reviewType === 2 ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setReviewType(2)}>
                            Tasting Note Only
                        </button>
                        <button type="button"
                                className={`btn ${reviewType === 1 ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setReviewType(1)}>
                            Both
                        </button>
                    </div>
                </div>
                {noOgError && (
                    <ErrorMessage message={
                        "Please fill out all fields before submitting."
                    }/>
                )}
                <div className="button-container">
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => onSaveClick()}>
                        Start My Review
                    </button>
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="primary-button"*/}
                    {/*    onClick={() => props.onCloseClick()}>*/}
                    {/*    Nevermind*/}
                    {/*</button>*/}

                </div>
            </div>
        </div>
    );
}
AddReviewModalDialog.propTypes = {
    onSaveClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
    // onCloseClick: PropTypes.func.isRequired
}

function DefinitionDialog(props) {

    return (
        <div className="DefinitionDialog"
             onClick={() => props.onCloseClick()}>
            <div className="card" onClick={() => props.onCloseClick()}>
                <p><b>Bottle Shock: </b>Currently defined as 2 months from bottle date for whites and 4 months for reds.
                For exact bottling date of a wine see its wine detail page.</p>
                <p><b>Source Guess: </b>Based on my determination of the consensus best guess from the <LinkOut onClick={()=>logExternalLink("WineBerserker Negoce Forum")}
                    url="https://www.wineberserkers.com/forum/viewtopic.php?f=1&t=169722"
                    text="WineBerserker de Negoce forum">
                    </LinkOut>.
                If you disagree or have comments about it, please put that in the comment/tasting notes section of the appropriate
                    wine detail page.</p>
                <p><b>Click to dismiss</b></p>
            </div>
        </div>
    );
}
DefinitionDialog.propTypes = {
    onCloseClick: PropTypes.func.isRequired
};

function WineList() {

    const [showWines, setShowWines] = useState(true);
    const [addReview, setAddReview] = useState(false);
    const [showDefinition, setShowDefinition] = useState(false);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    let history = useHistory();

    useLayoutEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            console.log("dimensions are"+dimensions.height+" and "+dimensions.width);
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 50);

        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    });

    useEffect(() => {
        logPageView("wine_list");
    },[]);

    // function goToDetail() {
    //     const ogNumberBox = document.getElementById("og-number");
    //     const detailOgNumber = ogNumberBox.value ? parseInt(ogNumberBox.value) : null;
    //     history.push(`/${detailOgNumber}`);
    //     // window.location.href = `/${detailOgNumber}`;
    // }

    function onSaveClick (ogNumber, reviewType) {
        history.push(`/addrating/${ogNumber}/${reviewType}`);
    }

    function startReviewSubmit() {
        setAddReview(true);
    }

    return (
        <div className="WineList">
            <Helmet>
                <title>
                    List of de Négoce Releases
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable ist of wines that have " +
                        "been released under the de Négoce label " +
                        "along with ratings and related information."
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            {/*<Link to="/add" className="primary-button">*/}
            {/*    Add a New Wine*/}
            {/*</Link>*/}
            <div className="WineList card">
                <p>
                    Please consider leaving a review and/or tasting note.  More info makes the site more useful for everyone! You can rate/review
                    on the wine detail page or by clicking the button below.
                </p>
                <button type="button"
                        className="primary-button"
                    onClick={() => startReviewSubmit()}
                >
                    Submit a review
                </button>
            </div>
            <div className="WineList card"
                onClick={() => setShowDefinition(true)}
            >
                How do you determine bottle shock or source guess?
            </div>
            {/*<button type="button"*/}
            {/*        className="secondary-button"*/}
            {/*        onClick={() => setShowDefinition(true)}*/}
            {/*>*/}
            {/*    How do you determine bottle shock or source guess?*/}
            {/*</button>*/}
            {showDefinition && (
                <DefinitionDialog
                    onCloseClick={() => setShowDefinition(false)}
                />
            )}
            {/*<div className="WineList card">*/}
            {/*    /!*<div className="label">Enter lot number to go directly to wine details</div>*!/*/}
            {/*    <input id="og-number"*/}
            {/*           type="number"*/}
            {/*           aria-describedby="og-number"*/}
            {/*           placeholder="Enter Lot/OG number"/>*/}
            {/*    <button type="button"*/}
            {/*            className="submit primary-button"*/}
            {/*            onClick={() => goToDetail()}>*/}
            {/*        Skip Directly To Wine Detail Page*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className="WineList card">
                <p>Contribute to the upkeep of de Négociants via Venmo</p>
                <img src={venmoQRSmall}/>
            </div>


            {(dimensions.width < 850) &&
            <div>
                <button type="button"
                        className={`btn ${showWines ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setShowWines(true)}>Wines</button>
                <button type="button"
                            className={`btn ${!showWines ? 'btn-info': 'btn-outline-info'}`}
                            onClick = {() => setShowWines(false)}>Comments</button>
                {showWines && <WineFeed showHeader={false}/>}
                {!showWines && <ListCommentFeed showHeader={false}/>}
            </div>
            }

            {(dimensions.width >= 850) &&
            <div className="list-container">
                <div className="list-of-wines">
                    <WineFeed showHeader={true}/>
                </div>
                <div className="list-of-comments">
                    <ListCommentFeed showHeader={true}/>
                </div>
            </div>}

            {addReview && (
                <AddReviewModalDialog
                    onSaveClick={(ogNumber,reviewType) => onSaveClick(ogNumber, reviewType)}
                    onOutsideClick={() => setAddReview(false)}
                    // onCloseClick={() => setAddReview(false)}
                />
            )}
        </div>
    )
}

export default WineList;

