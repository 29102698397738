import React, {useEffect, useState} from "react";
// noinspection ES6CheckImport
import {useParams} from "react-router-dom";
import api from "./api";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import Helmet from "react-helmet";
import "./EventDetail.css"
import EventCommentFeed from "./EventCommentFeed";

function EventDetail() {

    const ERROR_LOADING = "error_loading";
    const ERROR_NO_POST = "error_no_post";

    const {eventId} = useParams();
    const [event, setEvent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribe = api().collection("events")
            .where("eventId", "==", eventId)
            // .where('reportedTimestamp','==', null)
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setError(ERROR_NO_POST);
                    console.log("hit db in eventdetail 1");
                } else {
                    console.log("hit db in eventdetail 2");
                    console.log("event Id from querysnap is"+ querySnap.docs[0].id);
                    setEvent(querySnap.docs[0].data());
                }
            }, error => {
                console.log("hit db in eventdetail 3");
                setError(ERROR_LOADING);
            })

        return () => {
            unsubscribe()
        }
    },[eventId]);

    function getTitle() {
        let newTitle;
        if (event.eventName) {
            newTitle = event.eventName +" | Denegociants"
        } else {
            newTitle = "Event Detail | Denegociants"
        }
        return newTitle;
    }

    // function enterField(onClickCall, buttonText) {
    //     return (
    //         <button type="button"
    //                 className="btn btn-outline-info"
    //                 onClick={() => onClickCall()}>
    //             {/*onClick={() => window.location.href = {'/addrating/${wineId}/${wine.name}/${wine.number}'}}*/}
    //             {buttonText}
    //         </button>
    //     )
    // }

    // function addCasePrice() {
    //     window.location.href = `/addField/${win}/casePrice/number/Enter+Case+Price`;
    // }
    //
    // function addSource() {
    //     window.location.href = `/addField/${wineNum}/possibleSource/string/Enter+Best+Consensus+Source+Guess`;
    // }


    // function gotoBottles() {
    //     window.location.href = `/wineexchange/${wine.number}/${wine.wineId}`;
    // }
    //
    // function navToAddRating() {
    //     const navPath = "/addrating/"+wine.number;
    //     console.log("nav path is"+navPath);
    //     window.location.href = navPath;
    // }

    return <div className="EventDetail">
        {!error && !event && (
            <LoadingSpinner/>
        )}
        {error && error === ERROR_LOADING && (
            <ErrorMessage message={
                "There was an error loading the page! " +
                "Please confirm you are connected to the Internet and " +
                "try again."
            } withIcon={true}/>
        )}
        {error && error === ERROR_NO_POST && (
            <ErrorMessage message={
                "It doesn't look like " +
                "there's an event here. Please go back to the event list page " +
                "and try clicking the link again."
            }/>
        )}
        {!error && event && (
            <div>
                <Helmet>
                    <meta
                        name="description"
                        content="detailed info for particular de negoce event"
                    />
                    <meta
                        property="eventId:url"
                        content={window.location.href}
                    />
                    <meta property="eventId:type" content="website" />
                    <meta property="eventId:title" content={getTitle()} />
                    {/*{ wine && (*/}
                    {/*    <meta*/}
                    {/*        property="og:image:secure"*/}
                    {/*        content={shirt.image}*/}
                    {/*    />*/}
                    {/*)}*/}
                    <title>{getTitle()}</title>
                    <link
                        rel="canonical"
                        href={
                            `https://denegociants/events/${
                                eventId
                            }`
                        }
                    />
                </Helmet>
                <h1>{event.eventName}</h1>
                <div className="content-container">
                    <div className="detail">
                        <h5>Description</h5>
                        <p>{event.description ? event.description : "no description added"}</p>
                        <div>
                            <h5>Area</h5>
                            {event.area && (
                                <p>{`$${event.area}`}</p>
                            )}
                            {!event.casePrice && (
                                // <p>{enterField(addCasePrice,"Add Case Price")}</p>
                                <p>Will have add area</p>
                            )}
                        </div>
                        <div>
                            <h5>Event Date</h5>
                            <p>Will have add event date</p>
                        </div>
                        <div>
                            <h5>Event Location</h5>
                            <p> Will have add event location</p>
                        </div>
                        <div>
                            <h5>Event Time</h5>
                            <p>Will have add event time</p>
                        </div>
                        <div>
                            <h5>Attendees</h5>
                            <p>Will show number attending</p>
                        </div>
                        <div>
                            <h5>RSVP</h5>
                            <p>Will have place to RSVP</p>
                        </div>
                    </div>
                    <div className="comments">
                        <h5>Event Comments</h5>
                        <EventCommentFeed eventName={event.eventName} eventId={event.eventId}/>
                    </div>

                </div>
            </div>
        )}
    </div>
}

export default EventDetail;