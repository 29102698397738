import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import WineList from "./WineList";
import Header from "./Header"
import About from "./About";
import AddWine from "./AddWine";
import TopRated from "./TopRated";
import WineDetail from "./WineDetail";
import LeaderBoard from "./LeaderBoard";
import AddRating from "./AddRating";
import AddTrade from "./AddTrade";
import ExchangeList from "./ExchangeList";
import SubmitLeader from "./SubmitLeader";
import OgExchangeList from "./OgExchangeList";
import AddField from "./AddField";
import EventList from "./EventList";
import AddEvent from "./AddEvent";
import EventDetail from "./EventDetail";
import Feedback from "./Feedback";
import AddHolding from "./AddHolding";
import HoldingList from "./HoldingList";
import Upcoming from "./Upcoming";
import AddUpcoming from "./AddUpcoming";
import ReferralBanner from "./ReferralBanner";

function App() {
    return (
        <div className="App">
            <Router>
                <Header/>
                <ReferralBanner/>
                <Switch>
                    <Route path="/about" component={About}/>
                    <Route path="/events/:eventId" component={EventDetail}/>
                    <Route path="/holdings" component={HoldingList}/>
                    {/*<Route path="/holdings/:userLink" component={HoldingList}/>*/}
                    <Route path="/events" component={EventList}/>
                    <Route path="/toprated" component={TopRated}/>
                    <Route path="/feedback" component={Feedback}/>
                    <Route path="/leaderboard" component={LeaderBoard}/>
                    <Route path="/wineexchange/:og/:wineId" component={OgExchangeList}/>
                    <Route path="/wineexchange" component={ExchangeList}/>
                    <Route path="/upcoming" component={Upcoming}/>
                    <Route path="/addupcoming" component={AddUpcoming}/>
                    <Route path="/addrating/:og/:review" component={AddRating}/>
                    <Route path="/addField/:og/:fieldName/:dataType/:promptText" component={AddField}/>
                    <Route path="/addholding/:og" component={AddHolding}/>
                    <Route path="/addtrade" component={AddTrade}/>
                    <Route path="/addevent" component={AddEvent}/>
                    <Route path="/add"
                           component={AddWine}/>
                    <Route path="/submitleader"
                           component={SubmitLeader}/>
                    <Route path="/:og" component={WineDetail}/>
                    <Route path="/" component={WineList}/>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
