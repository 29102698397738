import React, {useEffect, useState} from "react";
import api from "./api";
import OgExchangeItem from "./OgExchangeItem";
import LoadingSpinner from "./LoadingSpinner";
import {useParams} from "react-router-dom";
import "./OgExchangeList.css";
import "./Common.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import TradeCommentFeed from "./TradeCommentFeed";
import {logPageView} from "./analytics";


function OgExchangeList() {

    const [trades, setTrades] = useState(null);
    const {og, wineId} = useParams();
    const wineNum = parseInt(og);
    console.log("wine number is"+ wineNum);

    useEffect(() => {
        logPageView("og_exchange_list");
    },[]);

    useEffect(() => {
        const unsubscribe = api().collection("trades")
            .where("wineNumber", "==", wineNum)
            .orderBy("timestamp", "asc")
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setTrades([]);
                    console.log("hit db in ogexchangelist 1");
                } else {
                    console.log("hit db in ogexchangelist 2");
                    setTrades(querySnap.docs.map(doc => {
                        let trade = doc.data();
                        return trade;
                    }));
                }
            }, error => {
                console.log("hit db in ogexchangelist 3");
                console.error(`Error loading trades in og exchangelist: ${error}`);
            })

        return () => {
            unsubscribe()
        }
    },[wineNum]);

    function getOgExchangeWantedItems() {
        let filteredTrades = trades.filter(trade => {
            return trade.offerType === "Wanted";
        });

        if (!filteredTrades.length) {
            return  (
                <div>
                    No known wanted yet. Add a new trade by clicking
                    the button above.
                </div>
            )
        } else {
            return filteredTrades.map(trade =>
                    <OgExchangeItem key={trade.tradeId} trade={trade}/>
                )
        }
    }

    function getOgExchangeAvailableItems() {
        let filteredTrades = trades.filter(trade => {
            return trade.offerType === "Available";
        });

        if (!filteredTrades.length) {
            return (
                <div>
                    No known available yet. Add a new trade by clicking
                    the button above.
                </div>
            )
        } else {
            return filteredTrades.map(trade =>
                    <OgExchangeItem key={trade.tradeId} trade={trade}/>
                )
        }
    }

    //not using placeholder for no exchanges yet bc I will seed it with wines

    return (
        <div className="OgExchangeList">
            <Helmet>
                <title>
                    List of Possible Exchanges for Specific Wine
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable ist of trades from people that have " +
                        "or want a specific de Négoce wine"
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <h3>Trades for OG {wineNum}</h3>
            <Link to="/addtrade" className="primary-button">
                Add a New Trade
            </Link>
            <div className="content-container">
                <div className="list-container">
                    <h5>Available</h5>
                    {!trades && (
                        <LoadingSpinner/>
                    )}
                    {trades && trades.length > 0 && getOgExchangeAvailableItems()}
                    {trades && trades.length === 0 && (
                        <div>
                            No trades yet. Add a new one by clicking
                            the button above.
                        </div>
                    )}
                </div>
                <div className="list-container">
                    <h5>Wanted</h5>
                    {!trades && (
                        <LoadingSpinner/>
                    )}
                    {trades && trades.length > 0 && getOgExchangeWantedItems()}
                    {trades && trades.length === 0 && (
                        <div>
                            No trades yet. Add a new one by clicking
                            the button above.
                        </div>
                    )}
                </div>
                <div className="comments">
                    <h5>Comments</h5>
                    {!trades && (
                        <LoadingSpinner/>
                    )}
                    {wineNum && (
                        <TradeCommentFeed wineNumber={wineNum} wineId={wineId}/>
                    )}
                    {/*wineNum might need to be state variable or something so doesn't render before assigned*/}
                </div>
            </div>
        </div>
    )
}

export default OgExchangeList;