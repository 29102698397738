import React, {useEffect, useState,} from "react";

import { v4 as uuidv4 } from 'uuid';
import PropTypes from "prop-types";
import "./WineCommentFeed.css";
import WineComment from "./WineComment.js";
import api from "./api";
import {logSubmitWineComment} from "./analytics";
import {Helmet} from "react-helmet";
import * as firebase from 'firebase/app'

import LoadingSpinner from "./LoadingSpinner";

function WineCommentFeed(props) {

    const [comments, setComments] = useState(null);
    const [errorLoading, setErrorLoading] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const wineId = props.wineId;
    const userId = props.userId;
    const userName = props.userName;
    const Timestamp = firebase.firestore.Timestamp;
    const commentPlaceholder = `Add to the conversation about OG N.${props.wineNumber}`;
    console.log("wineID in wimecommentfeed "+wineId);


    useEffect(() => {
        const unsubscribe = api().collection("comments")
            .where("wineId","==",wineId)
            // .where('reportedTimestamp','==', null)
            .orderBy('timestamp','desc')
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    console.log("hit db in commentfeed 1");
                    setComments([]);
                } else {
                    console.log("hit db in commentfeed 2");
                    setComments(querySnap.docs.map(doc => {
                        const comment = doc.data();
                        return comment;
                    }));
                }
            }, error => {
                console.log("hit db in commentfeed 3");
                console.error(`Error loading comments: ${error}`);
                setErrorLoading(true);
            })

        return () => {
            unsubscribe()
        }
    },[wineId]);

    // useEffect(() => {
    //     api().collection("wines")
    //         .doc(wineId)
    //         .collection('wineComments')
    //         // .where('reportedTimestamp','==', null)
    //         .orderBy('timestamp','desc')
    //         .get()
    //         .then(querySnap => {
    //             if (querySnap.empty) {
    //                 console.log("hit db in commentfeed 1");
    //                 setComments([]);
    //                 setDoneSavingNewComment(false);
    //             } else {
    //                 console.log("hit db in commentfeed 2");
    //                 setComments(querySnap.docs.map(doc => {
    //                     const comment = doc.data();
    //                     return comment;
    //                 }));
    //             }
    //         })
    //         .catch(error => {
    //             console.log("hit db in commentfeed 3");
    //             console.error(`Error loading comments: ${error}`);
    //             setErrorLoading(true);
    //         })
    // }, [doneSavingNewComment])
    //this was an endliess loop when had comments in deps, but I still don't quite understand why


    function getWineComments() {
        return comments.map(comment =>
            <WineComment key={comment.commentId} comment={comment}/>)
    }

    // function getCommentText() {
    //     if (comments == null) {
    //         commentText = <h1>LoadingCOmments</h1>
    //     } else if (comments === []) {
    //         commentText = <h1>No Comments</h1>
    //     } else {
    //         commentText = getWineComments();
    //     }
    // }

    function submitComment() {
        // this.setState({
        //     errorLoading: false,
        //     errorSaving: false
        // })
        const commentId = uuidv4();

        const commentTextBox = document.getElementById("new-comment");
        const userNameTextBox = document.getElementById("new-comment-username");
        let userName;

        const comment = commentTextBox.value;
        console.log("comment text is "+comment);
        userName = userNameTextBox.value;
        if (userName === "") userName = "Anonymous User";
        console.log("username is "+ userName);

        if (!comment) {
            return;
        }

        const newComment = {
            key: commentId,
            type: "Wine",
            wineId: wineId,
            commentId: commentId,
            // userId: currentUserId(),
            commentText: comment,
            userName: userName,
            wineName: props.wineName || null,
            wineNumber: props.wineNumber || null,
            timestamp: Timestamp.now(),
            reportedTimestamp: null,
            thumbsUpCount: 0,
        };


        api()
            .collection('comments')
            .doc(commentId)
            .set(newComment)
            .then(() => {
                commentTextBox.value = "";
                userNameTextBox.value = "";
                console.log("comment text box is "+ commentTextBox.value);
                console.log("name text is "+userNameTextBox.value);
                logSubmitWineComment(props.wineNumber);
            })
            .catch(error => {
                console.error(error.message);
                setErrorSaving(true);
            })
            .finally(()=> {
                if (props.userId) saveCommentToUser(commentId,comment);
            })
    }

    function saveCommentToUser(commentId, comment) {
        api().doc(`users/${userId}`)
            .collection('comments')
            .doc(commentId)
            .set({
                key: commentId,
                type: "Wine",
                wineId: wineId,
                commentId: commentId,
                commentText: comment,
                wineName: props.wineName,
                wineNumber: props.wineNumber,
                timestamp: Timestamp.now(),
            })
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
    }

    return (
        <div className="WineCommentFeed">
            <Helmet>
                <title>
                    Comments on wine for this page
                </title>
                <meta
                    name="description"
                    content={
                        "Comments that have been made about " +
                        props.wineName
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>

            <div className="comment-feed-container">
                <div className="comment-feed">
                    {(comments == null) && (
                        <LoadingSpinner/>
                    )}
                    {comments !== null && !comments.length && (
                        <p>
                            Be the first to comment on this wine by
                            using the form below.  Comments about when it will be good
                            to drink are particularly welcome 🙂
                        </p>
                    )}
                    {comments !== null && comments.length > 0 && (
                        <p><i>Information about whether this wine is currently drinking well or needs decanting/would be best cellared for a
                            certain amount of time first would be particularly welcome.</i></p>
                    )}
                    {comments !== null && comments.length > 0 && getWineComments()}
                </div>
                <div className="new-comment-container">
                    <div className="new-comment">
                        <div className="row">
                            <div className="col">
                                <textarea
                                    id="new-comment"
                                    className="form-control"
                                    rows="2"
                                    placeholder={commentPlaceholder}/>
                                    {/*changed to always show username input but default to username if logged in*/}
                                    {/*to allow user to change username if wanted for privacy rather than */}
                                    {/*just setting user to username of logged in user*/}
                                <input
                                    type="text"
                                    id="new-comment-username"
                                    className="form-control mt-2"
                                    defaultValue={props.userName}
                                    placeholder="Username to show with comment"/>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <button type="button"
                                        className="primary-button"
                                        onClick={() => submitComment()}>
                                    Submit your comment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

WineCommentFeed.propTypes = {
    wineNumber: PropTypes.number.isRequired,
    wineName: PropTypes.string.isRequired,
    wineId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

export default WineCommentFeed;