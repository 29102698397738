import React from "react";
import PropTypes from "prop-types";
import "./EventOverview.css";

function EventOverview(props) {

    return (
        <div className="event-overview">
            <div className="row text-center">
                <div className="col">
                    <h5>Description</h5>
                    <h6>{props.event.description ? props.event.description : "Not set"}</h6>
                </div>
            </div>
            <div className="row text-center">
                <div className="col">
                    <h5>Region</h5>
                    <h6>{props.event.eventLocation ? props.event.eventLocation : "Not set"}</h6>
                </div>
                <div className="col">
                    <h5>Organizer</h5>
                    <h6>{props.event.organizer ? props.event.organizer : "Not set"}</h6>
                </div>
            </div>
            <div className="row text-center">
                <div className="col">
                    <h5>Address</h5>
                    <h6>{props.event.address ? props.event.address : "Not yet set"}</h6>
                </div>
                <div className="col">
                    <h5>Date</h5>
                    <h6>{props.event.eventDate ? props.event.eventDate : "Not yet set"}</h6>
                </div>
                <div className="col">
                    <h5>Time</h5>
                    <h6>{props.event.eventStart ? props.event.eventStart : "Not yet set"}</h6>
                </div>
            </div>
        </div>
    )
}

EventOverview.propTypes = {
    event: PropTypes.object.isRequired,
};

export default EventOverview;