import firebaseApp from "./firebaseApp";

function logPageView(pageName) {
    analytics().logEvent(
        "page_view",
        {
            page_title: pageName,
            page_location: window.location.hostname,
            page_path: window.location.pathname,
        }
    );
}

function logSubmitRating(ogNumber) {
    analytics().logEvent(
        "submit_rating",
        {
            og_number: ogNumber,
        }
    );
}

function logSubmitWineComment(ogNumber) {
    analytics().logEvent(
        "submit_wine_comment",
        {
            og_number: ogNumber,
            page_location: window.location.hostname,
            page_path: window.location.pathname,
        }
    );
}

function logExternalLink(destLoc) {
  analytics().logEvent(
      "navigate_to_external_link",
      {dest_loc: destLoc}
  );
}

function analytics() {
    return firebaseApp().analytics()
}

export {
    logPageView, logSubmitRating, logSubmitWineComment, logExternalLink
};
