import React, {useEffect, useState} from "react";
import api from "./api";
import ExchangeItem from "./ExchangeItem";
import LoadingSpinner from "./LoadingSpinner";
import "./ExchangeList.css";
import "./Common.css";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {logPageView} from "./analytics";
import Select from "react-select";
import {stateList} from "./Common";
import WineItem from "./WineItem";

function SearchDialog(props) {

    const [geoState, setGeoState] = useState(false);
    const [includeShip, setIncludeShip] = useState(null);


    function onSaveClick() {
        props.onSaveClick(geoState.value,includeShip)
    }

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="SearchDialog"
             onClick={() => props.onOutsideClick()}>
            <div className="card" onClick={doNothing}>
                <div className="form">
                    <h4>Narrow search to a state</h4>
                    <div className="label">State</div>
                    <Select
                        onChange={setGeoState}
                        options={stateList.map(t=>({value: t, label: t}))}
                        placeholder="None Selected"
                    />
                    <div className="label">Include wine elsewhere that can be shipped?</div>
                    <div className="toggle-buttons">
                        <button type="button"
                                className={`btn ${includeShip === true ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setIncludeShip(true)}>
                            Yes
                        </button>
                        <button type="button"
                                className={`btn ${includeShip === false ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setIncludeShip(false)}>
                            No
                        </button>
                    </div>
                </div>
                <div className="button-container">
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => onSaveClick()}>
                        Search
                    </button>
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => props.onCloseClick()}>
                        Nevermind
                    </button>

                </div>
            </div>
        </div>
    );
}
SearchDialog.propTypes = {
    onSaveClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
}

function ExchangeList() {
    let stateTrades = null;
    let shipTrades = null;
    const [wines, setWines] = useState(null);
    const [filteredWines, setFilteredWines] = useState(null);
    const [ogNumArray, setOgNumArray] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [includeShip, setIncludeShip] = useState(null);
    const [searchState, setSearchState] = useState(null);

    useEffect(() => {
        logPageView("exchange_list");
    },[]);

    useEffect(() => {
        const unsubscribe = api().collection("wines")
            .orderBy("number", "asc")
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setWines([]);
                    console.log("hit db in exchangelist 1");
                } else {
                    console.log("hit db in exchangelist 2");
                    setWines(querySnap.docs.map(doc => {
                        let wine = doc.data();
                        return wine;
                    }).filter(wine => {
                        if ((wine.bottlesWanted > 0) || (wine.bottlesAvailable > 0)) {
                            return true;
                        }
                        return false;
                    }));
                }
            }, error => {
                console.log("hit db in exchangelist 3");
                console.error(`Error loading trades: ${error}`);
            })

        return () => {
            unsubscribe()
        }
    },[]);

    function getAvailableItems(wineList) {
        const tempWines = wineList.filter(wine => {
            return wine.bottlesAvailable > 0;
        });
        if (tempWines.length === 0) {
            return <h6>No known wines available</h6>
        } else {
            return wineList.filter(wine => {
                if (wine.bottlesAvailable > 0) {
                    return true;
                }
                return false;
            }).map(wine =>
                <ExchangeItem
                    key={wine.wineId}
                    wine={wine}
                    showAvailable={true}
                    showWanted={false}
                />);
        }
    }


    function onSaveClick(modalState,modalShip) {
        setIncludeShip(modalShip);
        setSearchState(modalState);
        setShowSearch(false);
        console.log("modalShip is "+modalShip);
        console.log("includeShip is "+includeShip);
        console.log("modalState is "+modalState);
        console.log("searchState is "+searchState);

        api().collection('trades')
            .where("state","==", modalState)
            .orderBy("timestamp","asc")
            .get()
            .then(querySnap =>{
                if (querySnap.empty) {
                    stateTrades = [];
                    console.log("hit db in ogsaveclick 1");
                } else {
                    console.log("hit db in ogsaveclick 2");
                    stateTrades = (querySnap.docs.map(doc => {
                        let trade = doc.data();
                        return trade;
                    }));
                }})
            .then(() =>
                {if (modalShip === true) {
                    getShippedTradeList(modalShip);
                } else {
                    setFilteredWineList(stateTrades);
                }}
            );
    }

    function getShippedTradeList(modalShip) {
        api().collection('trades')
            .where("willShip","==", modalShip)
            .orderBy("timestamp","asc")
            .get()
            .then(querySnap =>{
                if (querySnap.empty) {
                    shipTrades = [];
                    console.log("hit db in oggetshippedtrade 1");
                } else {
                    console.log("hit db in oggetshippedtrade 2");
                    shipTrades = (querySnap.docs.map(doc => {
                        let trade = doc.data();
                        return trade;
                    }));
                }})
            .then(() =>{
                let combinedTrades = stateTrades.concat(shipTrades);
                combinedTrades = Array.from(new Set(combinedTrades));
                setFilteredWineList(combinedTrades);
            });
    }

    function setFilteredWineList(trades) {
        let ogNumArray = trades.map(trade => trade.wineNumber);
        console.log("ogNumArray is "+ogNumArray);
        ogNumArray = Array.from(new Set(ogNumArray));
        console.log("unique og num array is"+ogNumArray);
        const tempFilteredWines = wines.filter(wine => ogNumArray.includes(wine.number));
        console.log("temp filtered wines"+tempFilteredWines);
        // setFilteredWines(wines.filter(wine => ogNumArray.includes(wine.number)));
        setFilteredWines(tempFilteredWines);
        console.log("filtered wines "+filteredWines);
    }


    function getWantedItems(wineList) {
        const tempWines = wineList.filter(wine => {
            if (wine.bottlesWanted > 0) {
                return true;
            }
            return false;
        });
        if (tempWines.length === 0) {
            return <h6>No known wines wanted</h6>
        } else {
            return tempWines.map(wine =>
                <ExchangeItem
                    key={wine.wineId}
                    wine={wine}
                    showAvailable={false}
                    showWanted={true}
                />);
        }
    }

    function returnToFullList() {
        setSearchState(null);
        setIncludeShip(null);
        setFilteredWines(null);
    }

    //not using placeholder for no exchanges yet bc I will seed it with wines

    return (
        <div className="ExchangeList">
            <Helmet>
                <title>
                    List of Possible Exchanges for All Wines
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable ist of trades from people that have " +
                        "or want various de Négoce wines "
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <Link to="/addtrade" className="primary-button">
                Add a New Trade
            </Link>
            {!searchState && (
                <button
                    type="button"
                    className="primary-button"
                    onClick={() => setShowSearch(true)}>
                    Narrow by State
                </button>
            )}
            {searchState && (
                <button
                    type="button"
                    className="primary-button"
                    onClick={() => returnToFullList()}>
                    Return to Full List
                </button>
            )}
            <div className="content-container">
                <div className="list-container">
                    <h5>Available</h5>
                    {!wines && (
                        <LoadingSpinner/>
                    )}
                    {(filteredWines && searchState) && getAvailableItems(filteredWines)}
                    {(wines && !searchState) && getAvailableItems(wines)}
                </div>
                <div className="list-container">
                    <h5>Wanted</h5>
                    {!wines && (
                        <LoadingSpinner/>
                    )}
                    {(filteredWines && searchState) && getWantedItems(filteredWines)}
                    {(wines && !searchState) && getWantedItems(wines)}
                </div>
            </div>
            {showSearch && (
                <SearchDialog
                    onSaveClick={(modalState, modalShip) => onSaveClick(modalState,modalShip)}
                    onOutsideClick={() => setShowSearch(false)}
                    onCloseClick={() => setShowSearch(false)} />
            )}
        </div>
    )
}

export default ExchangeList;
