import React, {useState} from "react";
import "./AddEvent.css";
import "./Common.css";
import api from "./api";
import { v4 as uuidv4 } from 'uuid';
import * as firebase from "firebase";
import ModalDialog from "./ModalDialog";
import ErrorMessage from "./ErrorMessage";


function AddEvent() {

    const [emptyFieldsError, setEmptyFieldsError] = useState(false);
    const [
        genericError,
        setGenericError
    ] = useState(false);

    function eventAddedComplete() {
        window.location.href = "/events"
    }

    function submitEvent() {
        const eventId = uuidv4();
        const usernameTextBox = document.getElementById("username");
        const eventNameTextBox = document.getElementById("event-name");
        const eventGroupAreaTextBox = document.getElementById("area");
        const cityTextBox = document.getElementById("city");
        const stateTextBox = document.getElementById("state");
        const countryTextBox = document.getElementById("country");
        const descriptionTextBox = document.getElementById("description");

        const submitUsername = usernameTextBox.value ? usernameTextBox.value : null;
        const submitEventName = eventNameTextBox.value ? eventNameTextBox.value : null;
        //eventNameSubmitted = submitEventName;
        const submitCity =  cityTextBox.value ? cityTextBox.value : null;
        const submitState =  stateTextBox.value ? stateTextBox.value : null;
        const submitCountry =  countryTextBox.value ? countryTextBox.value : null;
        const submitArea =  eventGroupAreaTextBox.value ? eventGroupAreaTextBox.value : null;
        const submitDescription =  descriptionTextBox.value ? descriptionTextBox.value : null;


        if (!(submitUsername && submitEventName && submitArea && submitDescription)) {
            setEmptyFieldsError(true);
            return;
        }

        const newEvent = {
            key: eventId,
            eventId: eventId,
            organizer: submitUsername,
            description: submitDescription,
            eventName: submitEventName,
            eventLocation: submitArea,
            city: submitCity,
            state: submitState,
            country: submitCountry,
            timestamp: firebase.firestore.Timestamp.now(),
        };

        api()
            .collection('events')
            .doc(eventId)
            .set(newEvent)
            .then(() => {
                eventAddedComplete();
            })
            .catch(error => {
                setGenericError(true);
            });
    }

    function closeGenericErrorMessage() {
        setGenericError(false);
    }

    // TODO: Toast isn't working

    return (
        <div className="AddEvent">
            <h5>Enter an event</h5>
            <div className="form">
                <h6>
                    <i>Must enter all fields before submitting!</i>
                </h6>
                <div className="row">
                    <div className="label">Event Name</div>
                    <input id="event-name"
                           type="string"
                           aria-describedby="event-name"
                           placeholder=""/>
                </div>
                <div className="row">
                    <div className="label">Event Description</div>
                    <input id="description"
                           type="string"
                           aria-describedby="description"
                           placeholder=""/>
                </div>
                <div className="row">
                    <div className="label">Desired geographical scope of participants</div>
                    <input id="area"
                           type="string"
                           aria-describedby="area"
                           placeholder="e.g. San Francisco Bay Area or DFW Metroplex"/>
                </div>
                <div className="row">
                    <div className="label">City the event will be held in</div>
                    <input id="city"
                           type="string"
                           aria-describedby="city"
                           placeholder="e.g. San Francisco"/>
                </div>
                <div className="row">
                    <div className="label">State the event will be in</div>
                    <input id="state"
                           type="string"
                           aria-describedby="state"
                           placeholder="2 letter abbreviation, e.g. TX"/>
                </div>
                <div className="row">
                    <div className="label">Country the event will be in</div>
                    <input id="country"
                           type="string"
                           aria-describedby="country"
                           placeholder=""/>
                </div>
                <div className="row">
                    <div className="label">Your name or username</div>
                    <input id="username"
                           type="string"
                           aria-describedby="username"
                           placeholder=""/>
                </div>
                {emptyFieldsError && (
                    <ErrorMessage message={
                        "Please fill out at least event name, area, description, and username."
                    }/>
                )}
                <button type="button"
                        className="submit primary-button"
                        onClick={() => submitEvent()}>
                    SUBMIT
                </button>
            </div>
            {genericError && (
                <ModalDialog
                    title="Hmm, something went wrong"
                    body={
                        "Sorry about that! Something went wrong, but we're not "
                        + "sure what. Please contact us to let us know!"
                    }
                    buttonText="Ok"
                    buttonAction={() => closeGenericErrorMessage()}
                    outsideClickAction={() => closeGenericErrorMessage()}/>
            )}
        </div>
    );

}

export default AddEvent;

