import React from "react";
import PropTypes from "prop-types";
import "./LeaderOrTradeComment.css";


function LeaderOrTradeComment(props) {


    return (
        <div>
            <div className="leader-comment-container card">
                <div className="leader-comment-text row">
                    <div className="col">
                        {props.comment.commentText}
                    </div>
                </div>
                <div className="leader-comment-footer d-flex">
                    <div className="flex-fill">
                        <i>{props.comment.userName}</i>
                    </div>
                    <div className="flex-fill">
                        <i>{props.comment.timestamp.toDate().toLocaleString()}</i>
                    </div>
                    {/*<div className="flex-fill">*/}
                    {/*    <button type="button"*/}
                    {/*            className="btn btn-light"*/}
                    {/*            onClick={() => this.incrementThankCount()}>*/}
                    {/*        👍 {thanksDisplay}*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                    {/*<button type="button"*/}
                    {/*        className="btn btn-danger"*/}
                    {/*        onClick={() => this.displayReportDialog(true)}>*/}
                    {/*    Report*/}
                    {/*</button>*/}
                </div>
            </div>
            {/*{ this.state.showReportConfirmation && (*/}
            {/*     <OneButtonDialog dialogTitle="Report this comment"*/}
            {/*                     onCloseClick={() => this.displayReportDialog(false)}*/}
            {/*                     onButtonClick={() => this.report()}*/}
            {/*                     buttonText="Report"*/}
            {/*                     buttonClass="btn-danger">*/}
            {/*        <p>*/}
            {/*            Are you sure you want to report this comment? Doing so*/}
            {/*            will mark it as inappropriate and remove it from the feed until*/}
            {/*            Grocery Status can review it.  If you*/}
            {/*            just think the information is outdated, please click the x to exit and*/}
            {/*            enter an updated comment instead.*/}
            {/*        </p>*/}
            {/*        <p>*/}
            {/*            Thanks for helping to make Grocery*/}
            {/*            Status a safe and valuable tool 👮🏽‍♀️*/}
            {/*        </p>*/}
            {/*    </OneButtonDialog>*/}
            {/*)}*/}
        </div>
        // <div className="wine-comment-container">
        //     <p>{props.comment.commentText ? props.comment.commentText : "No Comment"}</p>
        //
        //     <p>{props.comment.userName ? props.comment.userName : "No Comment"}</p>
        //
        //     <p>{props.comment.timestamp ? "Time submitted: "+props.comment.timestamp.toDate().toLocaleString() : "No time given"}</p>
        //
        // </div>
    )
}

LeaderOrTradeComment.propTypes = {
    comment: PropTypes.object.isRequired,
}

export default LeaderOrTradeComment;
// constructor(props) {
//     super(props);
//     this.state = {
//         showReportConfirmation: false,
//     };
// }
//
// function displayReportDialog(display) {
//     this.setState({
//         showReportConfirmation: display,
//     })
// }
//
// function getCommentRef() {
//     const googlePlacesId = this.props.googlePlacesId;
//     let commentRef;
//     if (googlePlacesId) {
//         commentRef = firestore().collection('stores')
//             .doc(googlePlacesId)
//             .collection('storeComments')
//     } else if (this.props.isPPE) {
//         commentRef = firestore()
//             .collection('ppeComments')
//     } else {
//         commentRef = firestore()
//             .collection('comments')
//     }
//     return commentRef;
// }
//
// function incrementThankCount() {
//     let commentRef = this.getCommentRef();
//     commentRef
//         .doc(this.props.commentId)
//         .set({
//             thankCount: firebase.firestore.FieldValue.increment(1)
//         }, {
//             merge: true
//         })
//         .catch(error => {
//             console.error(error.message);
//         })
//         .finally(() => {
//             //TODO something so shows like immediately?
//         })
// }
//
// function report() {
//     const googlePlacesId = this.props.googlePlacesId;
//     console.log("got into report with googleId "+googlePlacesId);
//     let commentRef = this.getCommentRef();
//     commentRef
//         .doc(this.props.commentId)
//         .set({
//             reportedTimestamp: Timestamp.now()
//         }, {
//             merge: true
//         })
//         .catch(error => {
//             console.error(error.message);
//         })
//         .finally(() => {
//             this.displayReportDialog(false);
//         })
// }
//
//     return (
//         <div>
//             <div className="grocery-store-comment-container card">
//                 <div className="grocery-store-comment-storeName row">
//                     { this.props.showStoreName && (
//                         <div className="col">
//                             {this.props.storeName}
//                         </div>
//                     )}
//                 </div>
//                 <div className="grocery-store-comment-text row">
//                     <div className="col">
//                         {this.props.comment}
//                     </div>
//                 </div>
//                 <div className="grocery-store-comment-footer d-flex">
//                     <div className="flex-fill">
//                         <i>{this.props.userName}</i>
//                     </div>
//                     <div className="flex-fill">
//                         <i>{this.props.timestamp.toDate().toLocaleString()}</i>
//                     </div>
//                     <div className="flex-fill">
//                         <button type="button"
//                                 className="btn btn-light"
//                                 onClick={() => this.incrementThankCount()}>
//                             👍 {thanksDisplay}
//                         </button>
//                     </div>
//
//                     <button type="button"
//                             className="btn btn-danger"
//                             onClick={() => this.displayReportDialog(true)}>
//                         Report
//                     </button>
//                 </div>
//             </div>
//             { this.state.showReportConfirmation && (
//                 <OneButtonDialog dialogTitle="Report this comment"
//                                  onCloseClick={() => this.displayReportDialog(false)}
//                                  onButtonClick={() => this.report()}
//                                  buttonText="Report"
//                                  buttonClass="btn-danger">
//                     <p>
//                         Are you sure you want to report this comment? Doing so
//                         will mark it as inappropriate and remove it from the feed until
//                         Grocery Status can review it.  If you
//                         just think the information is outdated, please click the x to exit and
//                         enter an updated comment instead.
//                     </p>
//                     <p>
//                         Thanks for helping to make Grocery
//                         Status a safe and valuable tool 👮🏽‍♀️
//                     </p>
//                 </OneButtonDialog>
//             )}
//         </div>
//     )
// }
// GroceryStoreComment.propTypes = {
//     commentId: PropTypes.string.isRequired,
//     comment: PropTypes.string.isRequired,
//     userName: PropTypes.string.isRequired,
//     timestamp: PropTypes.object.isRequired,
//     thankCount: PropTypes.number,
//     googlePlacesId: PropTypes.string,
//     storeName: PropTypes.string,
//     showStoreName: PropTypes.bool,
//     isPPE: PropTypes.bool,
// };

