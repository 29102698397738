import React, {useState, useEffect, useLayoutEffect} from "react";
import "./AddHolding.css";
import "./Common.css";
import {NONUMBER} from "./Common";
import api from "./api";
import { v4 as uuidv4 } from 'uuid';
import * as firebase from "firebase";
import {logPageView} from "./analytics";
import ModalDialog from "./ModalDialog";
import ErrorMessage from "./ErrorMessage";
import {useParams} from "react-router-dom";
import firebaseApp from "./firebaseApp";
import "firebase/auth";


class WineDoesNotExistError extends Error {
    constructor() {
        super("No wine with that number exists");
    }
}



function AddHolding() {

    let {og} = useParams();
    let wineNum = parseInt(og);
    console.log("wine num in add holding"+wineNum);
    const [emptyFieldsError, setEmptyFieldsError] = useState(false);
    const [user, setUser] = useState(null);
    const [
        wineDoesNotExistError,
        setWineDoesNotExistError
    ] = useState(false);
    const [
        genericError,
        setGenericError
    ] = useState(false);

    // let userLink;
    firebaseApp();

    useLayoutEffect(() => {
        setUser(firebase.auth().currentUser);
    },[]);

    useEffect(() => {
        logPageView("add_holding");
    },[]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    function holdingAddedComplete() {
            // window.location.href = `/holdings/${userLink}`
        window.location.href = "/holdings"
    }

    function getWine() {
        return api().collection("wines")
            .where("number","==", wineNum)
            .limit(1)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.empty) {
                    console.log("wine num in add holding getwine"+wineNum);
                    throw new WineDoesNotExistError();
                }
                const wineDoc = querySnapshot.docs[0].data();
                return wineDoc;
            })
    }

    // decided not to check if wine already exists in holdings
    // this will allow people who make multiple purchases or have the same wine in multiple locations
    // to have more than one entry if desired
    function addHoldingDoc(numberBottles, source, cellarLocation) {
        const holdingId = uuidv4();
        console.log("got into addholdingdoc");

        return getWine()
            .then(wine => {
                let bottlesPerCase = wine.bottlesPerCase ? wine.bottlesPerCase : 12;
                let shippingCost = wine.shippingCost ? wine.shippingCost : null;
                console.log("got in to first then in getwine");
                console.log("wine name in getwine is"+wine.name);
                const newHolding = {
                    key: holdingId,
                    holdingId: holdingId,
                    userId: user.uid,
                    wineNumber: wineNum,
                    wineName: wine.name,
                    wineId: wine.wineId,
                    varietal: wine.varietal,
                    type: wine.type,
                    numBottles: numberBottles,
                    cellarLocation: cellarLocation,
                    shippingCost: shippingCost,
                    inCellar: null,
                    casePrice: wine.casePrice || null,
                    bottlesPerCase: bottlesPerCase || null,
                    shipDate: wine.shipDate || null,
                    source: source,
                    timestamp: firebase.firestore.Timestamp.now(),
                };
                // return api()
                //     .doc(`holdings/${user.uid}`)
                //     .collection('userHoldings')
                //     .doc(holdingId)
                //     .set(newHolding);
                return api().doc(`users/${user.uid}`)
                    .collection('holdings')
                    .doc(holdingId)
                    .set(newHolding)
                    .catch(error => {
                        console.error(error.message);
                        // setErrorSaving(true);
                    })
            });
    }


    function submitHolding() {
        // const linkStringTextBox = document.getElementById("link-string");
        const sourceTextBox = document.getElementById("source");
        const numBottlesTextBox = document.getElementById("bottle-number");
        const cellarLocationTextBox = document.getElementById("cellar-location");
        const wineNumTextBox = document.getElementById("wine-number");

        const submitSource = sourceTextBox.value ? sourceTextBox.value : null;
        const submitBottleNumber = numBottlesTextBox.value ? parseInt(numBottlesTextBox.value) : null;
        // const submitLinkString = linkStringTextBox.value ? linkStringTextBox.value : null;
        const cellarLocationString = cellarLocationTextBox.value ? cellarLocationTextBox.value : null;
        if (wineNumTextBox) {
            wineNum = wineNumTextBox.value ? parseInt(wineNumTextBox.value) : null;
        }
        // userLink = submitLinkString;

        if (!(submitBottleNumber && wineNum)) {
            setEmptyFieldsError(true);
            return;
        }

        addHoldingDoc(submitBottleNumber,submitSource,cellarLocationString)
            .then(()=> {
                holdingAddedComplete();
            })
            .catch(error =>{
                console.log(error)
            })
    }

    function onAddWineClick() {
        setWineDoesNotExistError(false)
        window.open("/add");
    }

    function onOutsideAddWineClick() {
        setWineDoesNotExistError(false)
    }

    function closeGenericErrorMessage() {
        setGenericError(false);
    }

    // TODO: Toast isn't working

    return (
        <div className="AddHolding">
            <div className="content-container">
                <h5>Enter your wine</h5>
                <div className="form">
                    {/*<div className="row">*/}
                    {/*    <div className="label">What is the unique link/username for your holding page</div>*/}
                    {/*    <input id="link-string"*/}
                    {/*           type="string"*/}
                    {/*           aria-describedby="link-string"*/}
                    {/*           placeholder="For example 'mbclone' or '152793'"/>*/}
                    {/*</div>*/}
                    {(wineNum == NONUMBER) &&
                        <div className="row">
                            <div className="label">What is the Lot/OG number of your wine?</div>
                            <input id="wine-number"
                                   type="number"
                                   aria-describedby="wine-number"
                                   placeholder=""/>
                        </div>
                    }
                    <div className="row">
                        <div className="label">How many bottles do you have?</div>
                        <input id="bottle-number"
                               type="number"
                               aria-describedby="bottle-number"
                               placeholder=""/>
                    </div>
                    <div className="row">
                        <div className="label">Cellar Location</div>
                        <input id="cellar-location"
                               type="string"
                               aria-describedby="cellar-location"
                               placeholder=""/>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="label">Is it already in your cellar?</div>*/}
                    {/*    <div className="toggle-buttons">*/}
                    {/*        <button type="button"*/}
                    {/*                className={`btn ${inCellar === true ? 'btn-info' : 'btn-outline-info'} toggle-button`}*/}
                    {/*                onClick={() => setInCellar(true)}>*/}
                    {/*            In Cellar*/}
                    {/*        </button>*/}
                    {/*        <button type="button"*/}
                    {/*                className={`btn ${inCellar === false ? 'btn-info' : 'btn-outline-info'} toggle-button`}*/}
                    {/*                onClick={() => setInCellar(false)}>*/}
                    {/*            Waiting for shipment*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row">
                        <div className="label">Note (optional)</div>
                        <i>For instance "4 to go to Jim" or "Will get 6 from Sarah"</i>
                        <input id="source"
                               type="string"
                               aria-describedby="source"
                               placeholder=""/>
                    </div>



                    {emptyFieldsError && (
                        <ErrorMessage message={
                            "You must fill out the number of bottles you have before submitting. " +
                            "If you added a holding from the holdings page (rather than the detail page) you must also enter the lot number."
                        }/>
                    )}
                    <button type="button"
                            className="submit primary-button"
                            onClick={() => submitHolding()}>
                        SUBMIT
                    </button>
                </div>
                {wineDoesNotExistError && (
                    <ModalDialog
                        title="That wine isn't in our system yet"
                        body={
                            "Sorry about that! The wine with the OG number "
                            + "you entered doesn't exist quite yet. Would you mind "
                            + "adding it for us? Please click the 'Add wine' button "
                            + "below. Don't worry; we'll open a new tab so you don't "
                            + "lose your place here."
                        }
                        buttonText="Add wine"
                        buttonAction={() => onAddWineClick()}
                        outsideClickAction={() => onOutsideAddWineClick()}/>
                )}
                {genericError && (
                    <ModalDialog
                        title="Hmm, something went wrong"
                        body={
                            "Sorry about that! Something went wrong, but we're not "
                            + "sure what. Please contact us to let us know!"
                        }
                        buttonText="Ok"
                        buttonAction={() => closeGenericErrorMessage()}
                        outsideClickAction={() => closeGenericErrorMessage()}/>
                )}
            </div>
        </div>
    );

}

export default AddHolding;