import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import "./AddWine.css";
import "./Common.css";
import "./Common.js"
import api from "./api";
import { v4 as uuidv4 } from 'uuid';
import ErrorMessage from "./ErrorMessage";
import ModalDialog from "./ModalDialog";
import {logPageView} from "./analytics";
import {typeList, varietalList} from "./Common";

class WineAlreadyExistsError extends Error {
    constructor() {
        super('This wine already exists in the database.');
    }
}

function AddUpcoming() {

    const history = useHistory();
    const [varietal, setVarietal] = useState(null);
    const [wineType, setWineType] = useState(null);
    const [
        insufficientInfoError,
        setInsufficientInfoError
    ] = useState(false);
    const [
        errorWineAlreadyExists,
        setErrorWineAlreadyExists
    ] = useState(false);
    const [errorSavingWine, setErrorSavingWine] = useState(false);
    // TODO: Toast isn't working

    useEffect(() => {
        logPageView("add_upcoming");
    },[]);

    function openUpcomingPage() {
        history.replace('/upcoming');
    }

    function submitUpcoming() {
        const upcomingId = uuidv4();
        const avaTextBox = document.getElementById("ava");
        const numberTextBox = document.getElementById("wine-number");
        const abvTextBox = document.getElementById("abv");
        const vintageYearTextBox = document.getElementById("wine-vintage");

        const submitAva = avaTextBox.value;
        const submitNumber = numberTextBox.value ? parseInt(numberTextBox.value) : null;
        const submitAbv = abvTextBox.value ? parseFloat(abvTextBox.value) : null;
        const submitVintageYear = vintageYearTextBox.value ? parseInt(vintageYearTextBox.value) : null;
        console.log("ava, number, abv, year are"+submitAva + submitNumber+submitAbv+submitVintageYear);

        if (!(submitAva && submitNumber && submitAbv && submitVintageYear && varietal && wineType)) {
            setInsufficientInfoError(true);
            return;
        }

        const newUpcoming = {
            key: upcomingId,
            upcomingId: upcomingId,
            number: submitNumber,
            ava: submitAva,
            abv: submitAbv,
            varietal: varietal,
            type: wineType,
            vintage: submitVintageYear,
        };

        console.log("got into submit upcoming");

        api().collection('upcoming')
            .where("number", "==", submitNumber)
            .get()
            .then(querySnap => {
                console.log("got into then in number check in add upcoming");
                // If the wine already exists, we don't want to add it again.
                if (!querySnap.empty) {
                    throw new WineAlreadyExistsError();
                }
            })
            .then(() => {
                console.log("got into second then after number check in add upcoming");
                return api()
                    .collection('upcoming')
                    .doc(upcomingId)
                    .set(newUpcoming)
                    .then(() => {
                        console.log("got into then in set new upcoming");
                    });
            })
            .then(() => openUpcomingPage())
            .catch(error => {
                if (error instanceof WineAlreadyExistsError) {
                    setErrorWineAlreadyExists(true);
                } else {
                    setErrorSavingWine(true);
                }
            })
    }

    function getTypeToSelectFrom() {
        let typeButtons = [];
        typeList.forEach(buttonType => typeButtons.push(
            <button type="button"
                    className={`btn ${wineType === buttonType ? 'btn-info' : 'btn-outline-info'}`}
                    onClick={() => setWineType(buttonType)}>
                {buttonType}
            </button>
        ));
        return typeButtons;

    }

    function getVarietalsToSelectFrom() {
        let varietalButtons = [];
        varietalList.forEach(buttonVarietal => varietalButtons.push(
            <button type="button"
                    className={`btn ${varietal === buttonVarietal ? 'btn-info' : 'btn-outline-info'}`}
                    onClick={() => setVarietal(buttonVarietal)}>
                {buttonVarietal}
            </button>
        ))
        return varietalButtons;

    }

    return (
        <div className="AddWine">
            <h5>Add Upcoming Wine from COLA</h5>
            <div className="form">
                <h6>
                    <i>Must enter all fields before submitting</i>
                </h6>
                <div>
                    <div className="row">
                        <div className="label">OG Number</div>
                        <input id="wine-number"
                               type="number"
                            //onChange={input => numberAdded(input)}
                               aria-describedby="wine-number"
                               placeholder="Enter OG number (E.g. '40')"/>
                    </div>
                    <div className="row">
                        <div className="label">Vintage Year</div>
                        <input id="wine-vintage"
                               type="number"
                            //onChange={input => {vintageYearAdded(input)}}
                               aria-describedby="wine-cost"
                               placeholder="Enter vintage year of wine"/>
                    </div>
                    <div className="row">
                        <div className="label">AVA</div>
                        <input id="ava"
                               type="string"
                            //onChange={input => nameAdded(input)}
                               aria-describedby="ava"
                               placeholder="Enter AVA of wine"/>
                    </div>
                    <div className="row">
                        <div className="label">Alcohol Percentage</div>
                        <input id="abv"
                               type="number"
                            //onChange={input => {reviewLinkAdded(input)}}
                               aria-describedby="abv"
                               placeholder="ABV listed for wine"/>
                    </div>
                    <div className="row">
                        <div className="label">What is the varietal of the wine?</div>
                    </div>
                    <div className="multi-select-row">
                        {getVarietalsToSelectFrom()}
                    </div>
                    <div className="row">
                        <div className="label">What is the type of the wine?</div>
                    </div>
                    <div className="multi-select-row">
                        {getTypeToSelectFrom()}
                    </div>
                </div>
                {insufficientInfoError && (
                    <ErrorMessage message={
                        "Please fill out all fields "
                        + "before submitting."
                    } />
                )}
                <button type="button"
                        className="submit primary-button"
                        onClick={() => submitUpcoming()}>
                    SUBMIT
                </button>
            </div>
            {errorSavingWine && (
                <ModalDialog
                    title="There was an error saving that wine"
                    body={
                        "Looks like there was an issue saving that wine ... "
                        + "Sorry about that! Please make sure you're connected "
                        + "to the Internet. If you are, please get in touch with "
                        + "us and we'll troubleshoot the issue."
                    }
                    buttonText="Ok"
                    buttonAction={() => setErrorSavingWine(false)}
                    outsideClickAction={() => setErrorSavingWine(false)}
                />
            )}
            {errorWineAlreadyExists && (
                <ModalDialog
                    title="That wine is already in our system"
                    body={
                        "It looks like a wine with the OG number you entered "
                        + "already exists in our system. Please find that wine "
                        + "on the upcoming page to view or edit its information."
                    }
                    buttonText="Go to the upcoming page"
                    buttonAction={openUpcomingPage}
                    outsideClickAction={() => setErrorWineAlreadyExists(false)}
                />
            )}
        </div>
    );

}

export default AddUpcoming;