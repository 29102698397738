import React, {useEffect, useState,} from "react";

import WineItem from "./WineItem.js";
import api from "./api";
import {Helmet} from "react-helmet";
import LoadingSpinner from "./LoadingSpinner";
import PropTypes from "prop-types";

let wineList = null;

function WineFeed(props) {

    const [wines, setWines] = useState(null);
    const [filteredWines, setFilteredWines] = useState(null);


    useEffect(() => {
        console.log("wineList says",wineList);
        if (wineList) {
            setWines(wineList);
            return;
        }
        const unsubscribe = api().collection("wines")
            .orderBy("number", "asc")
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setWines([]);
                    console.log("hit db in winelist if");
                } else {
                    console.log("hit db in winelist else");
                    wineList = querySnap.docs.map(doc => {
                        let wine = doc.data();
                        return wine;
                    });
                    setWines(wineList);
                }
            }, error => {
                console.log("hit db in winelist error");
                console.error(`Error loading wines: ${error}`);
            });
            return () => {
                unsubscribe()
            }

    },[]);

    function getWineItems() {
        if (filteredWines) {
            console.log("got in if in getwineiterms winefeed");
            return filteredWines.map(wine =>
                <WineItem key={wine.wineId} wine={wine}/>)
        }
        console.log("outside if in getwineiterms winefeed");
        return wines.map(wine =>
            <WineItem key={wine.wineId} wine={wine}/>)
    }

    function handleSearch(searchTerm) {
        console.log("search term type is", typeof searchTerm);
        console.log("search term is", searchTerm);
        if (searchTerm == "") {
            setFilteredWines(null);
        } else {
            const filteredWineList = wines.filter((wine)=> (parseInt(wine.number) == parseInt(searchTerm)));
            setFilteredWines(filteredWineList);
            console.log("filteredwinelist in handle search winefeed else is", filteredWineList)
        }
    }

    return (
        <div >
            <Helmet>
                <title>
                    List of all wines for main page
                </title>
                <meta
                    name="description"
                    content="Summary of all wines"
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <div className="WineFeed">
                {!wines && (
                    <LoadingSpinner/>
                )}
                {wines && props.showHeader && <div>
                     <h4>Wines</h4>
                </div>}
                <button className="primary-button">
                    <input type="number" placeholder="Search for Lot #" onChange={(event) => handleSearch(event.target.value)} />
                </button>
                {wines && getWineItems()}
            </div>
        </div>
    )
}

WineFeed.propTypes = {
    showHeader: PropTypes.bool.isRequired,
};
export default WineFeed;