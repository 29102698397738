import React, {useState} from "react";
import PropTypes from "prop-types";
import "./UpcomingWineItem.css";
import api from "./api";
import ModalDialog from "./ModalDialog";

function DeleteModalDialog(props) {

    function onSaveClick() {
        props.onSaveClick();
    }

    return (
        <ModalDialog
            title={props.headingText}
            buttonText="Delete Wine"
            buttonAction={onSaveClick}
            outsideClickAction={props.onCloseClick}>
        </ModalDialog>
    )
}

DeleteModalDialog.propTypes = {
    defaultText: PropTypes.string.isRequired,
    headingText: PropTypes.string.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
};

function UpcomingWineItem(props) {

    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    const [errorDeleting, setErrorDeleting] = useState(false);

    function onSaveClick() {
        api()
            .collection('upcoming')
            .doc(props.wine.upcomingId)
            .delete()
            .catch(error => {
                setErrorDeleting(true)
                console.error(error.message);
            })
            .finally(()=>{
                setShowDeleteItemModal(false)
            })
    }

    return (
        <div id={props.wine.upcomingId}
             className="UpcomingWineItem card">
            <div className="row text-center">
                <div className="col">
                    <h5>OG</h5>
                    <h6>{props.wine.number}</h6>
                </div>
                <div className="col">
                    <h5>Vintage</h5>
                    <h6>{props.wine.vintage}</h6>
                </div>
                <div className="col">
                    <h5>AVA</h5>
                    <h6>{props.wine.ava}</h6>
                </div>
                <div className="col">
                    <h5>Varietal</h5>
                    <h6>{props.wine.varietal}</h6>
                </div>
                <div className="col">
                    <h5>ABV</h5>
                    <h6>{props.wine.abv}%</h6>
                </div>
                <div className="col">
                    <button type="button"
                            className="secondary-button"
                            onClick={() => setShowDeleteItemModal(true)}
                    >
                        Delete
                    </button>
                </div>
            </div>

            {showDeleteItemModal && (
                <DeleteModalDialog headingText={"Please only delete if this upcoming wine has been offered for sale on De Negoce"}
                                   onSaveClick={() => onSaveClick()}
                                   onCloseClick={() => setShowDeleteItemModal(false)}/>
            )}

            {errorDeleting && (
                <ModalDialog
                    title="Problem deleting item"
                    body={
                        "Sorry about that! It looks like there was an error "
                        + "deleting this wine. Please make sure you're "
                        + "connected to the Internet and try again. If there's "
                        + "still a problem, please contact us!"
                    }
                    buttonText="OK"
                    buttonAction={() => setErrorDeleting(false)}
                    outsideClickAction={() => setErrorDeleting(false)} />
            )}
        </div>
    )
}
UpcomingWineItem.propTypes = {
    wine: PropTypes.object.isRequired,
};

export default UpcomingWineItem;
