import React from "react";
import PropTypes from "prop-types";
import "./WineItem.css";
import "./Common.css";
import TradeOverview from "./TradeOverview";
import {Link} from "react-router-dom";

function ExchangeItem(props) {

    return (
        <div id={props.wine.wineId}
             className="TradeItem card"
            // onClick={() => this.updateUrlAndNavigate(this.props.store.googlePlacesId)}
            //onMouseEnter={() => this.onHover()}
            //onMouseLeave={() => this.onExitHover()}
        >
            {/*<h3>{props.wine.name}</h3>*/}
            <Link to={`/wineexchange/${props.wine.number}/${props.wine.wineId}`}
                className="card-item-title-link">
                {props.wine.name}
            </Link>
            <h2>  </h2>
            {/*<h3 className="pb-1">{props.wine.name}</h3>*/}
            <TradeOverview
                wine={props.wine}
                showAvailable={props.showAvailable}
                showWanted={props.showWanted}
            />
            {/*TODO available for split/trade*/}
        </div>
    )
}
ExchangeItem.propTypes = {
    wine: PropTypes.object.isRequired,
    showAvailable: PropTypes.bool,
    showWanted: PropTypes.bool,
};

export default ExchangeItem;
