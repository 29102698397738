import React, {useEffect, useState, useLayoutEffect} from "react";
// noinspection ES6CheckImport
import {useParams, useHistory} from "react-router-dom";
import api from "./api";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import Helmet from "react-helmet";
import "./WineDetail.css"
import WineCommentFeed from "./WineCommentFeed";
import RatingsOverview from "./RatingsOverview";
import LinkOut from "./LinkOut";
import {logExternalLink, logPageView} from "./analytics";
import {debounce} from "./Common";
import * as firebase from "firebase";


function WineDetail() {

    const ERROR_LOADING = "error_loading";
    const ERROR_NO_POST = "error_no_post";

    let {og} = useParams();
    let history = useHistory();
    const wineNum = parseInt(og);
    //let wineId = useRef();
    const [wine, setWine] = useState(null);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [showDetailTab, setShowDetailTab] = useState(true);
    const [showSourceInstructions, setShowSourceInstructions] = useState(false);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useLayoutEffect(() => {
        //taking the below out for now to see if it will work quickly enough in useEffect
        //if leave here put something in dependencies to limit how many times it's run
        // setUser(firebase.auth().currentUser);

        const debouncedHandleResize = debounce(function handleResize() {
            console.log("dimensions are"+dimensions.height+" and "+dimensions.width);
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 50);

        window.addEventListener('resize', debouncedHandleResize);

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    });

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    console.log("firebase user is",firebase.auth().currentUser);

    useEffect(() => {
        logPageView("wine_detail");
    },[]);

    useEffect(() => {
        const unsubscribe = api().collection("wines")
            .where("number", "==", wineNum)
            // .where('reportedTimestamp','==', null)
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setError(ERROR_NO_POST);
                    console.log("hit db in winedetail was empty");
                } else {
                    console.log("hit db in winedetail got list");
                    console.log("wine Id from querysnap is"+ querySnap.docs[0].id);
                    setWine(querySnap.docs[0].data());
                }
            }, error => {
                console.log("hit db in winedetail error");
                setError(ERROR_LOADING);
            })

        return () => {
            unsubscribe()
        }
    },[wineNum]);

    let userId = user ? user.uid : null;
    let userName = user ? user.displayName : null;

    function getTitle() {
        let newTitle;
        if (wine.name) {
            newTitle = wine.name +" | Denegociants"
        } else {
            newTitle = "Wine Detail | Denegociants"
        }
        return newTitle;
    }

    function goToDetail() {
        const ogNumberBox = document.getElementById("og-number");
        const detailOgNumber = ogNumberBox.value ? parseInt(ogNumberBox.value) : null;
        if (detailOgNumber != null) history.push(`/${detailOgNumber}`);
        // window.location.href = `/${detailOgNumber}`;
    }

    function enterField(onClickCall, buttonText) {
        return (
            <button type="button"
                    className="btn btn-outline-info"
                    onClick={() => onClickCall()}>
                {/*onClick={() => window.location.href = {'/addrating/${wineId}/${wine.name}/${wine.number}'}}*/}
                {buttonText}
            </button>
        )
    }

    function addCasePrice() {
        window.location.href = `/addField/${wineNum}/casePrice/number/Enter+Case+Price`;
    }

    function addSource() {
        window.location.href = `/addField/${wineNum}/possibleSource/string/Enter+Consensus+Source+Guess`;
    }

    function addFirstDrinkYear() {
        window.location.href = `/addField/${wineNum}/drinkBeginningYear/number/Enter+First+Year+For+Prime+Drinking`;
    }

    function addLastDrinkYear() {
        window.location.href = `/addField/${wineNum}/drinkEndingYear/number/Enter+Approximate+Last+Year+For+Prime+Drinking`;
    }

    function addNegoceLink() {
        window.location.href = `/addField/${wineNum}/negoceLink/string/Enter+Link+to+OG+on+DeNegoce`;
    }

    function addBottleDate() {
        window.location.href = `/addField/${wineNum}/bottleDate/date/Enter+Bottle+Date`;
    }

    function addShipDate() {
        window.location.href = `/addField/${wineNum}/shipDate/date/Enter+Ship+Date`;
    }

    function addCellartrackerLink() {
        window.location.href = `/addField/${wineNum}/cellartrackerReviewLink/string/Enter+Link+to+Reviews+on+Cellartracker`;
    }

    function goToAddHolding() {
        window.location.href = `/addHolding/${wineNum}`;
    }


    function addBottles() {
        window.location.href = `/addTrade`;
    }

    function gotoBottles() {
        window.location.href = `/wineexchange/${wine.number}/${wine.wineId}`;
    }

    function getBottlesPerCase() {
        let bottlesPerCase = 12;
        if (wine.bottlesPerCase) bottlesPerCase = wine.bottlesPerCase;
        return bottlesPerCase;
    }

    // function getCommentFeedXML() {
    //     return(
    //         <WineCommentFeed wineNumber={wine.number} wineName={wine.name} wineId={wine.wineId}
    //                          userId={userId} userName={userName}/>
    //     )
    // }

    function getDetailsXML() {
       return(
            <div className="detail">
                <h5>Description</h5>
                <p>{wine.description ? wine.description : "no description added"}</p>
                <RatingsOverview wine={wine}/>
                <div className="colPair">
                    <div>
                        <h5>Price per case of {getBottlesPerCase()}</h5>
                        {wine.casePrice && (
                            <p>{`$${wine.casePrice}`}</p>
                        )}
                        {!wine.casePrice && (
                            <p>{enterField(addCasePrice,"Add Case Price")}</p>
                        )}
                    </div>
                    <div className = "rightColumn">
                        <h5>Source Guess <span className="value-define"
                                               onClick={() => setShowSourceInstructions(oldValue => !oldValue)}>
                                            🔎
                                        </span></h5>
                        {showSourceInstructions && <p>This should be based on your best estimate of the crowd consensus guess.
                            Normally there is discussion about source guesses on the
                            <LinkOut url="https://www.wineberserkers.com/forum/viewtopic.php?f=1&t=169722" text=" DeNegoce offers thread "></LinkOut>
                            on Wine Berserkers at around the time the wine is released.</p>}
                        {wine.possibleSource && (
                            <p>{`${wine.possibleSource}`}</p>
                        )}
                        {!wine.possibleSource && (
                            <p>{enterField(addSource,"Add Source Guess")}</p>
                        )}
                    </div>
                </div>
                <div className = "colPair">
                    <div>
                        <h5>Bottle Date</h5>
                        {wine.bottleDate && (
                            <p>{`${wine.bottleDate}`}</p>
                        )}
                        {!wine.bottleDate && (
                            <p>{enterField(addBottleDate,"Add Bottle Date")}</p>
                        )}
                    </div>
                    <div className = "rightColumn">
                        <h5>Optimal drinking window</h5>
                        {(wine.drinkBeginningYear && wine.drinkEndingYear) && (
                            <p>{wine.drinkBeginningYear} - {wine.drinkEndingYear}</p>
                        )}
                        {(wine.drinkBeginningYear && !wine.drinkEndingYear) && (
                            <p>{wine.drinkBeginningYear} - {enterField(addLastDrinkYear,"Add End Year")}</p>
                        )}
                        {(!wine.drinkBeginningYear && wine.drinkEndingYear) && (
                            <p>{enterField(addFirstDrinkYear,"Add Beginning Year")} - {wine.drinkEndingYear}</p>
                        )}
                        {(!wine.drinkBeginningYear && !wine.drinkEndingYear) && (
                            <p>{enterField(addFirstDrinkYear,"Add Beginning Year")} - {enterField(addLastDrinkYear,"Add End Year")}</p>
                        )}
                    </div>
                </div>
                <div className = "colPair">
                    <div>
                        <h5>Bottles Available: {wine.bottlesAvailable ? wine.bottlesAvailable : "None"}</h5>
                        {wine.bottlesAvailable!==0 && (
                            <p>{enterField(gotoBottles,"See Available Bottles")}</p>
                        )}
                        {wine.bottlesAvailable===0 && (
                            <p>{enterField(addBottles,"Add Available Bottles")}</p>
                        )}
                    </div>
                    <div className = "rightColumn">
                        <h5>Bottles Wanted: {wine.bottlesWanted ? wine.bottlesWanted : "None"}</h5>
                        {wine.bottlesWanted!==0 && (
                            <p>{enterField(gotoBottles,"See Bottles Wanted")}</p>
                        )}
                        {wine.bottlesWanted===0 && (
                            <p>{enterField(addBottles,"Add Wanted Bottles")}</p>
                        )}
                    </div>
                </div>
                <div className = "colPair">
                    <div>
                        <h5>Link to Product</h5>
                        {wine.negoceLink &&  <button type="button"
                                                     className="btn btn-outline-info">
                            {/*//onClick={() =*/}
                            {<LinkOut
                                onClick={()=>logExternalLink("DeNegoce Product Page")}
                                url={wine.negoceLink}
                                text="Go to deNegoce Product Page"/>}
                        </button>
                        }
                        {!wine.negoceLink && (
                            <p>{enterField(addNegoceLink,"Add deNegoce Link")}</p>
                        )}
                    </div>
                    <div className = "rightColumn">
                        <h5>Link to Reviews</h5>
                        {wine.cellartrackerReviewLink && (
                            <button type="button"
                                    className="btn btn-outline-info">
                                {/*//onClick={() =*/}
                                {<LinkOut
                                    onClick={()=>logExternalLink("CellarTracker Reviews")}
                                    url={wine.cellartrackerReviewLink}
                                    text="Go To CellarTracker Reviews"/>}
                            </button>
                        )}
                        {!wine.cellartrackerReviewLink && (
                            <p>{enterField(addCellartrackerLink,"Add Cellartracker Link")}</p>
                        )}
                    </div>
                </div>
                <div className = "colPair">
                    <div>
                        <h5>Shipping Week</h5>
                        {wine.shipDate && (
                            <p>{`${wine.shipDate}`}</p>
                        )}
                        {!wine.shipDate && (
                            <p>{enterField(addShipDate,"Add Ship Date")}</p>
                        )}
                    </div>
                    <div className = "rightColumn">
                        <h5>Add to My List</h5>
                        {!user && <button type="button"
                                className="btn btn-outline-info"
                                aria-disabled="true"
                                disabled>
                            Need to Login to Use
                        </button>}
                        {user && <button type="button"
                                className="btn btn-outline-info"
                                onClick={() => goToAddHolding()}>
                                    Add to Holdings
                        </button>}
                    </div>
                    {/*<div>*/}
                    {/*    <h5>     . </h5>*/}
                    {/*    <button type="button"*/}
                    {/*            className="btn btn-light"*/}
                    {/*            onClick={() => goToAddHolding()}>*/}
                    {/*        🤓*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }

    return <div className="WineDetail">
        {!error && !wine && (
            <LoadingSpinner/>
        )}
        {error && error === ERROR_LOADING && (
            <ErrorMessage message={
                "There was an error loading the page! " +
                "Please confirm you are connected to the Internet and " +
                "try again."
            } withIcon={true}/>
        )}
        {error && error === ERROR_NO_POST && (
            <div>
                <ErrorMessage message={
                     "It doesn't look like " +
                     "there's a wine here."
                 }/>
                 <p></p>
                <div className="topNavigateCard">
                    <label >Go directly to detail for another wine &nbsp;&nbsp;
                        <input id="og-number"
                               type="number"
                               aria-describedby="og-number"
                               placeholder="Enter Lot/OG number"/>
                    </label>

                    <button type="button"
                            className="submit primary-button"
                            onClick={() => goToDetail()}>
                        Go
                    </button>
                </div>
             </div>

            // <ErrorMessage message={
            //     "It doesn't look like " +
            //     "there's a wine here. Please go back to the wine list page " +
            //     "and try again."
            // }/>
        )}
        {!error && wine && (
            <div>
                <Helmet>
                    <meta
                        name="description"
                        content="fill in content description later"
                    />
                    <meta
                        property="og:url"
                        content={window.location.href}
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content={getTitle()} />
                    {/*{ wine && (*/}
                    {/*    <meta*/}
                    {/*        property="og:image:secure"*/}
                    {/*        content={shirt.image}*/}
                    {/*    />*/}
                    {/*)}*/}
                    <title>{getTitle()}</title>
                    <link
                        rel="canonical"
                        href={
                            `https://denegociants/${
                                wine.number
                            }`
                        }
                    />
                </Helmet>
                <div className="topNavigateCard">
                    <label >Go directly to detail for another wine &nbsp;&nbsp;
                        <input id="og-number"
                               type="number"
                               aria-describedby="og-number"
                               placeholder="Enter Lot/OG number"/>
                    </label>

                    <button type="button"
                            className="submit primary-button"
                            onClick={() => goToDetail()}>
                        Go
                    </button>
                </div>
                <h1>{wine.name}</h1>
                {(dimensions.width < 850) &&
                <div>
                    <button type="button"
                            className={`btn ${showDetailTab ? 'btn-info' : 'btn-outline-info'}`}
                            onClick={() => setShowDetailTab(true)}>Details</button>
                    <button type="button"
                            className={`btn ${!showDetailTab ? 'btn-info': 'btn-outline-info'}`}
                            onClick = {() => setShowDetailTab(false)}>Comments</button>
                    {showDetailTab && getDetailsXML()}
                    {!showDetailTab && <WineCommentFeed wineNumber={wine.number} wineName={wine.name} wineId={wine.wineId}
                                userId={userId} userName={userName}/>}
                </div>
                }
                {(dimensions.width >= 850) &&
                <div className="content-container">
                    {getDetailsXML()}
                    <div className="comments">
                        <h5>Tasting Notes/Comments</h5>
                        <WineCommentFeed wineNumber={wine.number} wineName={wine.name} wineId={wine.wineId}
                            userId={userId} userName={userName}/>
                    </div>
                </div>
                }
            </div>
            )}
    </div>
}

export default WineDetail;
