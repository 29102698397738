import React, {useEffect, useLayoutEffect, useState} from "react";
import {useParams} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import "./AddRating.css";
import api from "./api";
import * as firebase from 'firebase/app'
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import {logPageView, logSubmitRating, logSubmitWineComment} from "./analytics";
import PropTypes from "prop-types";


function RatingThanksModalDialog(props) {

    return (
        <div className="AddReviewModalDialog"
             onClick={() => props.onCloseClick()}>
            <div className="card" onClick={()=> props.onCloseClick()}>
                <h5>Thanks for contributing by adding a rating! 😄  </h5>
                <h5>If you didn't submit a tasting
                    note and would like to, you can add it in the tasting notes/comments section at the bottom or side
                    of the wine detail page 🍷</h5>
                <div className="button-container">
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => props.onCloseClick()}>
                        Got it!
                    </button>
                </div>
            </div>
        </div>
    );
}
RatingThanksModalDialog.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
}

function AddRating () {

    const ERROR_LOADING = "error_loading";
    const ERROR_NO_POST = "error_no_post";
    const [emptyFieldsError, setEmptyFieldsError] = useState(false);
    const [showThanks, setShowThanks] = useState(false);
    const [rating, setRating] = useState(null);
    const [yesNo, setYesNo] = useState(null);
    const [wine, setWine] = useState(null);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);


    let {og, review} = useParams();
    const wineNum = parseInt(og);
    //if comes from wine detail page won't have reviewType in URL
    const reviewType = review ? parseInt(review) : 0;
    console.log("param ogNumber is"+wineNum);
    console.log("reviewType is "+reviewType);

    const Timestamp = firebase.firestore.Timestamp;

    let qpr = null;
    let userName = null;
    let qprs = null;
    let numRatings = null;
    let wouldDrink = null;

    useLayoutEffect(() => {
        setUser(firebase.auth().currentUser);
    },[]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setUser(user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    console.log("firebase user is",firebase.auth().currentUser);


    useEffect(() => {
        logPageView("add_rating");
    },[]);

    useEffect(() => {
        api().collection("wines")
            .where("number", "==", wineNum)
            .get()
            .then(querySnap => {
                if (querySnap.empty) {
                    console.log("hit db in addrating 1");
                    setError(ERROR_NO_POST);
                } else {
                    console.log("hit db in addrating 2");
                    console.log("wine Id from querysnap is wine.id"+ querySnap.docs[0].id);
                    // wineId = querySnap.docs[0].id;
                    setWine(querySnap.docs[0].data());
                }
            })
            .catch(() => {
                console.log("hit db in addrating 3");
                setError(ERROR_LOADING);
            })
    }, [wineNum]);

    // const qprTextBox = document.getElementById("qpr-guess");
    // const usernameTextBox = document.getElementById("username");
    //
    // function onQprAdded(qprGuess) {
    //     qpr = qprTextBox.value ? parseInt(qprGuess.value) : null;
    // }
    //
    // function onUsernameAdded(userName) {
    //     user = usernameTextBox.value ? userName.value : null;
    // }

    function saveComment() {
        // this.setState({
        //     errorLoading: false,
        //     errorSaving: false
        // })
        const commentId = uuidv4();
        const Timestamp = firebase.firestore.Timestamp;
        const commentTextBox = document.getElementById("tasting-note");
        const userNameTextBox = document.getElementById("username");
        const userName = userNameTextBox.value ? userNameTextBox.value : "Anonymous User";
        const comment = commentTextBox.value;
        if (!comment) return;
        console.log("comment text is "+comment);
        console.log("username is "+ userName);

        const newComment = {
            key: commentId,
            type: "Wine",
            wineId: wine.wineId,
            commentId: commentId,
            userId: user ? user.uid: null,
            commentText: comment,
            userName: userName,
            wineName: wine.name || null,
            wineNumber: wine.number || null,
            timestamp: Timestamp.now(),
            reportedTimestamp: null,
            thumbsUpCount: 0,
        };

        api()
            .collection('comments')
            .doc(commentId)
            .set(newComment)
            .catch(error => {
                console.error(error.message);
            })
            .finally(()=> {
                logSubmitWineComment(wine.number);
                setShowThanks(true);
                if (user) saveCommentToUser(commentId,comment);
            })
    }

    function submitRatings() {
        const qprTextBox = document.getElementById("qpr-guess");
        const usernameTextBox = document.getElementById("username");
        const commentTextBox = document.getElementById("tasting-note");
        let comment = null;
        if (commentTextBox) {
            comment = commentTextBox.value ? commentTextBox.value : null;
        }

        if (qprTextBox) {
            qpr = qprTextBox.value ? parseInt(qprTextBox.value) : null;
        }

        if ((reviewType === 0) && (!qpr || !rating || yesNo === null)) {
            setEmptyFieldsError(true);
            return;
        } else if (reviewType === 1 && (!comment || !qpr || !rating || yesNo === null)) {
            setEmptyFieldsError(true);
            return;
        } else if (reviewType === 2 && (!comment)) {
            setEmptyFieldsError(true);
            return;
        }

        if (usernameTextBox) {
            userName = usernameTextBox.value ? usernameTextBox.value : "Anonymous User";
        } else {
            userName = user.displayName;
        }
        qprs = wine.qprs;
        qprs.push(qpr);
        numRatings = wine.numRatings;
        numRatings.push(rating);
        wouldDrink = wine.wouldDrink;
        wouldDrink.push(yesNo);

        if (reviewType === 0 || reviewType ===1) {
            saveTopLevelRatings();
            console.log("wineId is"+ wine.wineId);
            console.log("qpr is"+ qpr);
            console.log("user is"+ userName);
            console.log("rating is"+ rating);
            console.log("yesno is"+ yesNo);
        } else if (reviewType === 2) {
            saveComment();
        }

    }

    function saveTopLevelRatings() {
        api().doc(`wines/${wine.wineId}`)
            .update({
                // userId: user ? user.uid : null, don't think need user in top level
                qprs: qprs,
                numRatings: numRatings,
                wouldDrink: wouldDrink,
            })
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
            .finally(() => {
                saveRatingsCollectionandExit();
                if (user) saveRatingToUser();
            })
    }

    function saveRatingsCollectionandExit() {
        api().doc(`wines/${wine.wineId}`)
            .collection('ratings')
            .add({
                userId: user? user.uid : null,
                userName: userName,
                timestamp: Timestamp.now(),
                qprPrice: qpr,
                wineRating: rating,
                wouldDrink: yesNo
            })
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
            .finally(() => {
                logSubmitRating(wineNum);
                if (reviewType === 1) {
                    saveComment()
                } else {
                    setShowThanks(true);
                }
            })
            // .then(() => {
            //     firebase.analytics().logEvent(
            //         'save_store_overview',
            //         {
            //             google_places_id: this.googlePlacesId,
            //             safetyStatus: this.safe,
            //             inventoryStatus: this.inventory,
            //             waitTimeToEntryMins: this.waitTime
            //         })
            // })
    }

    function saveCommentToUser(commentId, comment) {
        api().doc(`users/${user.uid}`)
            .collection('comments')
            .doc(commentId)
            .set({
                key: commentId,
                type: "Wine",
                wineId: wine.wineId,
                commentId: commentId,
                commentText: comment,
                wineName: wine.name || null,
                wineNumber: wineNum,
                timestamp: Timestamp.now(),
            })
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
    }

    function saveRatingToUser() {
        api().doc(`users/${user.uid}`)
            .collection('ratings')
            .doc(wine.wineId)
            .set({
                key: wine.wineId,
                timestamp: Timestamp.now(),
                qprPrice: qpr,
                wineRating: rating,
                wouldDrink: yesNo,
                wineNumber: wineNum,
                wineId: wine.wineId,
            })
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
    }

    return (
        <div className="AddRating">
            {!error && !wine && (
                <LoadingSpinner/>
            )}
            {error && error === ERROR_LOADING && (
                <ErrorMessage message={
                    "There was an error loading the page! " +
                    "Please confirm you are connected to the Internet and " +
                    "try again."
                } withIcon={true}/>
            )}
            {error && error === ERROR_NO_POST && (
                <ErrorMessage message={
                    "It doesn't look like " +
                    "there's a wine with that OG Number. Please try again."
                } withIcon={true}/>
            )}
            {!error && wine && (
                <div classname = "card">
                    {(reviewType === 1 || reviewType === 2) && (
                        <div>
                            <div className="row mt-3">
                                <div className="col">
                                    <h5>Add your tasting note for OG N.{wine.number}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <textarea id="tasting-note"
                                        // onChange={input => onUsernameAdded(input)}
                                           className="form-control"
                                           aria-describedby="tasting-note"/>
                                </div>
                            </div>
                        </div>
                    )}
                    {(reviewType === 0 || reviewType === 1) && (
                        <div>
                            <div className="row">
                                <div className="col">
                                    <h5>
                                        How would you rate {wine.name}?
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <i>1 = Ugh. Hard pass., 5 = Nectar of the Gods</i>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <button type="button"
                                            className={`btn ${rating === 1 ? 'btn-info' : 'btn-outline-info'}`}
                                            onClick={() => setRating(1)}>
                                        1
                                    </button>
                                </div>
                                <div className="col pl-0">
                                    <button type="button"
                                            className={`btn ${rating === 2 ? 'btn-info' : 'btn-outline-info'}`}
                                            onClick={() => setRating(2)}>
                                        2
                                    </button>
                                </div>
                                <div className="col pl-0">
                                    <button type="button"
                                            className={`btn ${rating === 3 ? 'btn-info' : 'btn-outline-info'}`}
                                            onClick={() => setRating(3)}>
                                        3
                                    </button>
                                </div>
                                <div className="col pl-0">
                                    <button type="button"
                                            className={`btn ${rating === 4 ? 'btn-info' : 'btn-outline-info'}`}
                                            onClick={() => setRating(4)}>
                                        4
                                    </button>
                                </div>
                                <div className="col pl-0">
                                    <button type="button"
                                            className={`btn ${rating === 5 ? 'btn-info' : 'btn-outline-info'}`}
                                            onClick={() => setRating(5)}>
                                        5
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <h5>Did you enjoy {wine.name}?</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-5">
                                    <button type="button"
                                            className={`btn ${yesNo === 1
                                                ? 'btn-success' : 'btn-outline-success'}`}
                                            onClick={() => setYesNo(1)}>
                                        Yes, I'd have it again
                                    </button>
                                </div>
                                <div className="col-7">
                                    <button type="button"
                                            className={`btn ${yesNo === 0
                                                ? 'btn-danger' : 'btn-outline-danger'}`}
                                            onClick={() => setYesNo(0)}>
                                        No, not up my alley.
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col">
                                    <h5>What price do you think a wine like this is worth?</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <input id="qpr-guess"
                                           type="number"
                                        // onChange={input => onQprAdded(input)}
                                           className="form-control"
                                           aria-describedby="qpr-guess"
                                           placeholder="Enter integer only (in dollars)"/>
                                </div>
                            </div>
                        </div>
                    )}
                    {/*changed from only showing displayname input if not logged (and then setting user to username of person logged in)*/}
                    {/*to always showing displayname input but defaulting to logged in username in order to allow user to alter default */}
                    {/*so don't accidentally show their full name*/}
                    <div>
                        <div className="row mt-3">
                            <div className="col">
                                <h5>Submit your username</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input id="username"
                                       type="string"
                                       defaultValue={user?.displayName}
                                    // onChange={input => onUsernameAdded(input)}
                                       className="form-control"
                                       aria-describedby="username"
                                       placeholder="Please use same username for all submissions"/>
                            </div>
                        </div>
                    </div>
                    {emptyFieldsError && (
                        <ErrorMessage message={
                            "Please fill out all fields before submitting."
                        }/>
                    )}
                    <button type="button"
                            className="primary-button"
                            onClick={() => submitRatings()}>
                        Submit
                    </button>
                    {showThanks && (
                        <RatingThanksModalDialog
                            onCloseClick={() => window.location.href = `/${wineNum}`}
                        />
                    )}
            </div>
            )}
        </div>)
}

export default AddRating;






