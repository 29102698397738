import React, {useEffect, useState,} from "react";

// import "./WineCommentFeed.css";
import WineComment from "./WineComment.js";
import api from "./api";
import {Helmet} from "react-helmet";

import LoadingSpinner from "./LoadingSpinner";
import PropTypes from "prop-types";

let commentList = null;

function ListCommentFeed(props) {

    const [comments, setComments] = useState(null);
    const [errorLoading, setErrorLoading] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);

    useEffect(() => {
        if (commentList) {
            setComments(commentList);
            return;
        }
        const unsubscribe = api().collection("comments")
            .where("type","==","Wine")
            .orderBy('timestamp','desc')
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    console.log("hit db in main commentfeed if");
                    setComments([]);
                } else {
                    console.log("hit db in main commentfeed else");
                    commentList = querySnap.docs.map(doc => doc.data());
                    setComments(commentList)
                }
            }, error => {
                console.log("hit db in main commentfeed error");
                console.error(`Error loading comments: ${error}`);
                setErrorLoading(true);
            });

        return () => {
            unsubscribe()
        }
    },[]);

    function getWineComments() {
        return comments.map(comment =>
            <WineComment key={comment.commentId} comment={comment} wineName={comment.wineName}/>)
    }

    return (
        <div className="CommentList">
            <Helmet>
                <title>
                    Comments on all wines
                </title>
                <meta
                    name="description"
                    content="Comments that have been made about all wine"
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <div>
                {!comments && (
                    <LoadingSpinner/>
                )}
                {comments && props.showHeader && <div>
                    <h4>Comments</h4>
                </div>}
                {comments && getWineComments()}
            </div>
        </div>
    )
}

ListCommentFeed.propTypes = {
    showHeader: PropTypes.bool.isRequired,
};
export default ListCommentFeed;