import "./HoldingItem.css";
import React from "react";
import HoldingItem from "./HoldingItem";

function HoldingDivider() {
return (
    <div className="HoldingItem card">
        <h4> Lots Entirely Consumed </h4>
    </div>
)}

export default HoldingDivider;