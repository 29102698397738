import React from "react";
import "./ReferralBanner.css";
import LinkOut from "./LinkOut";
import {logExternalLink} from "./analytics";

function ReferralBanner() {

    return (
        <div className="ReferralBanner">
            <h5>New to De Négoce?  <LinkOut
                onClick={()=>logExternalLink("DeNegoce Referral Link")}
                url="http://dengoce.refr.cc/talamanca149"
                text="Click Here"/> for $25 off your order of $99+</h5>
        </div>
    )
}

export default ReferralBanner;
