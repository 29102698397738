import React, {useState} from "react";
import PropTypes from "prop-types";
import api from "./api";
import "./OgExchangeItem.css";
import "./Common.css";
import ModalDialog from "./ModalDialog";
import * as firebase from "firebase";
import EmailContactModalDialog from "./EmailContactModalDialog";

function EditExchangeItemModalDialog(props) {

    function onSaveClick() {
        const numBottlesInput = document.getElementById("numBottles");
        props.onSaveClick(numBottlesInput.value)
    }

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="EditExchangeItemModalDialog"
            onClick={() => props.onOutsideClick()}>
            <div className="card" onClick={doNothing}>
                <h4>Edit this trade</h4>
                <i>Please only edit this exchange if you are {props.exchangerName}</i>
                <h5>{props.type === "Wanted" ? "Bottles wanted" : "Bottles available"}</h5>
                <input
                    id="numBottles"
                    type="number"
                    min="1"
                    defaultValue={props.bottleNumber}/>
                <div className="button-container">
                    <button
                        type="button"
                        className="negative-button"
                        onClick={() => props.onDeleteClick()}>
                        Delete trade
                    </button>
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => onSaveClick()}>
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    );
}
EditExchangeItemModalDialog.propTypes = {
    exchangerName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    bottleNumber: PropTypes.number.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
}

function OgExchangeItem(props) {

    const [
        contactExchangeItem,
        setContactExchangeItem
    ] = useState(false);
    const [editExchangeItem, setEditExchangeItem] = useState(false);
    const [
        errorDeletingTrade,
        setErrorDeletingTrade
    ] = useState(false);
    const [errorEditingTrade, setErrorEditingTrade] = useState(false);

    function onSaveNumBottles(numBottles) {
        setEditExchangeItem(false);
        const wineId = props.trade.wineId;
        const offerType = props.trade.offerType;
        const initialNumBottles = props.trade.numBottles;
        const numBottlesDifference = numBottles - initialNumBottles;
        api().collection("trades")
            .doc(props.trade.tradeId)
            .update({
                numBottles: numBottles,
            })
            .then(() => {
                // Todo: It would be better to do this in a cloud function.
                return updateNumBottles(offerType, wineId, numBottlesDifference)
            })
            .catch(() => {
                setErrorEditingTrade(true);
            })
    }

    function onDeleteTrade() {
        setEditExchangeItem(false);
        const offerType = props.trade.offerType;
        const wineId = props.trade.wineId;
        const initialNumBottles = props.trade.numBottles;
        api().collection("trades")
            .doc(props.trade.tradeId)
            .delete()
            .then(() => {
                // Todo: It would be better to do this in a cloud function.
                return updateNumBottles(offerType, wineId, -initialNumBottles)
            })
            .catch(() => {
                setErrorDeletingTrade(true);
            });
    }

    function updateNumBottles(offerType, wineId, incrementBy) {
        let dbField;
        if (offerType === "Wanted") {
            dbField = "bottlesWanted";
        } else {
            dbField = "bottlesAvailable";
        }
        return api().doc(`wines/${wineId}`)
            .update({
                [dbField]: firebase.firestore.FieldValue.increment(incrementBy)
            });
    }

    function hideErrorDeletingTrade() {
        setErrorDeletingTrade(false);
    }

    function hideErrorEditingTrade() {
        setErrorEditingTrade(false);
    }

    function getWillShipText(willShip) {
        if (willShip === false) {
            return "No";
        } else {
            return "Yes";
        }
    }

    return (
        <div id={props.trade.tradeId}
             className="OgExchangeItem card">
            <div className="row text-center">
                <div className="col">
                    <h5>Bottles</h5>
                    <h6>{props.trade.numBottles ? props.trade.numBottles : "None Found"}</h6>
                </div>
                <div className="col">
                    <h5>Exchanger</h5>
                    <h6>{props.trade.username ? props.trade.username : "None Given"}</h6>
                </div>
                <div className="col">
                    <h5>City</h5>
                    <h6>{props.trade.city ? props.trade.city : "None Given"}</h6>
                </div>
                {/* Todo: make these links to specific wine trade page */}
                <div className="col">
                    <h5>State</h5>
                    <h6>{props.trade.state ? props.trade.state : "None Given"}</h6>
                </div>
                <div className="col">
                    <h5>Will Ship</h5>
                    <h6>{(props.trade.willShip !== null) ? getWillShipText(props.trade.willShip) : "Unknown"}</h6>
                </div>
                <div className="col">
                    <div
                        className="link-button"
                        onClick={() => setContactExchangeItem(true)}
                    >
                        Contact
                    </div>
                    <div className="link-button" onClick={() => setEditExchangeItem(true)}>
                        Edit
                    </div>
                </div>
            </div>
            {contactExchangeItem && (
                <EmailContactModalDialog
                    title={
                        props.trade.offerType === "Wanted"
                            ? `Email the user looking for OG ${props.trade.wineNumber}`
                            : `Email the user offering OG ${props.trade.wineNumber}`
                    }
                    toEmail={props.trade.userEmail}
                    onSuccess={() => setContactExchangeItem(false)}
                    onOutsideClick={() => setContactExchangeItem(false)}/>
            )}
            {editExchangeItem && (
                <EditExchangeItemModalDialog
                    exchangerName={props.trade.username}
                    type={props.trade.offerType}
                    bottleNumber={props.trade.numBottles}
                    onSaveClick={numBottles => onSaveNumBottles(numBottles)}
                    onDeleteClick={onDeleteTrade}
                    onOutsideClick={() => setEditExchangeItem(false)}/>
            )}
            {errorDeletingTrade && (
                <ModalDialog
                    title="There was an error deleting that trade"
                    body={
                        "Sorry about that, but we couldn't delete that trade. "
                        + "Please contact us and we'll get this sorted out!"
                    }
                    buttonText="Ok"
                    buttonAction={hideErrorDeletingTrade}
                    outsideClickAction={hideErrorDeletingTrade}/>
            )}
            {errorEditingTrade && (
                <ModalDialog
                    title="There was an error editing that trade"
                    body={
                        "Sorry about that, but we couldn't edit that trade. "
                        + "Please contact us and we'll get this sorted out!"
                    }
                    buttonText="Ok"
                    buttonAction={hideErrorEditingTrade}
                    outsideClickAction={hideErrorEditingTrade}/>
            )}
        </div>
    )
}
OgExchangeItem.propTypes = {
    trade: PropTypes.object.isRequired,
};

export default OgExchangeItem;