import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessage.css"

function ErrorMessage(props) {
    return <div className="ErrorMessage">
        {props.withIcon && (
            <div className="icon">😬</div>
        )}
        <div className="error">
            {props.message}
        </div>
    </div>
}
ErrorMessage.propTypes = {
    message: PropTypes.string.isRequired,
    withIcon: PropTypes.bool,
};

export default ErrorMessage;
