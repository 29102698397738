import React from "react";
import "./ModalDialog.css"
import "./Common.css"
import PropTypes from "prop-types";

function ModalDialog(props) {

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="ModalDialog"
             onClick={props.outsideClickAction}>
            <div className="card" onClick={doNothing}>
                <div className="content-container">
                    <h4>
                        {props.title}
                    </h4>
                    <div className="body">
                        {props.body}
                        {props.children}
                    </div>
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => props.buttonAction()}>
                        {props.buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
}
ModalDialog.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonAction: PropTypes.func.isRequired,
    outsideClickAction: PropTypes.func.isRequired,
};

export default ModalDialog;
