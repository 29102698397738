import * as firebase from "firebase/app";

function config() {
    if (window.location.hostname === 'denegociants.com') {
        return {
            apiKey: "AIzaSyAk08wGkDan33Xm1EcT1yTzUNYrP6oFcng",
            authDomain: "denegociants.firebaseapp.com",
            databaseURL: "https://denegociants.firebaseio.com",
            projectId: "denegociants",
            storageBucket: "denegociants.appspot.com",
            messagingSenderId: "282055786455",
            appId: "1:282055786455:web:e30e89d39fe7a6c017d592",
            measurementId: "G-66YT0GWMSG"
        };
    } else {
        return {
            apiKey: "AIzaSyDiyEt8IEad5r4_ORBrivugxcHo8KPYojE",
            authDomain: "denegociants-dev.firebaseapp.com",
            databaseURL: "https://denegociants-dev.firebaseio.com",
            projectId: "denegociants-dev",
            storageBucket: "denegociants-dev.appspot.com",
            messagingSenderId: "455329168805",
            appId: "1:455329168805:web:ecf9a739570bb8fbf2fc4c",
            measurementId: "G-Y0EPG79LG8"
        };
    }
}

function firebaseApp() {
    if (!firebase.apps.length) {
        firebase.initializeApp(config());
    }
    return firebase.app();
}

export default firebaseApp;
