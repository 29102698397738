import React from "react";
import "./TopRated.css";

function TopRated() {
    return (
        <div>
            <p>Top Rated</p>
        </div>
    )
}

export default TopRated;
