import React from "react";
import PropTypes from "prop-types";
import "./WineItem.css";
import "./Common.css";
import EventOverview from "./EventOverview";
import {Link} from "react-router-dom";

function EventItem(props) {

    return (
        <div id={props.event.eventId}
             className="EventItem card"
            // onClick={() => this.updateUrlAndNavigate(this.props.store.googlePlacesId)}
            //onMouseEnter={() => this.onHover()}
            //onMouseLeave={() => this.onExitHover()}
        >
            {/*<h3>{props.wine.name}</h3>*/}
            <Link to={`/events/${props.event.eventId}`}
                  className="card-item-title-link">
                {props.event.eventName}
            </Link>
            <h2>  </h2>
            {/*<h3 className="pb-1">{props.wine.name}</h3>*/}
            <EventOverview event={props.event}/>
            {/*TODO available for split/trade*/}
        </div>
    )
}
EventItem.propTypes = {
    event: PropTypes.object.isRequired,
};

export default EventItem;