import React, {useEffect, useState} from "react";
import "./SubmitLeader.css";
import api from "./api";
import { v4 as uuidv4 } from 'uuid';
import {logPageView} from "./analytics";

function SubmitLeader() {

    const [purchaserType, setPurchaserType] = useState(null);

    useEffect(() => {
        logPageView("submit_leader");
    },[]);

    function leaderAdded() {
        window.location.href = "/leaderboard"
    }

    function submitLeader() {
        const leaderId = uuidv4();
        const nameTextBox = document.getElementById("purchaser-name");
        const numberTextBox = document.getElementById("case-number");


        const submitName = nameTextBox.value;
        const submitNumber = numberTextBox.value ? parseInt(numberTextBox.value) : null;


        if (!(submitName && submitNumber)) {
            return;
        }

        const newLeader = {
            key: leaderId,
            leaderId: leaderId,
            casesBought: submitNumber,
            purchaserName: submitName,
            // userId: currentUserId(),
            purchaserType: purchaserType,
        };


        api()
            .collection('leaders')
            .doc(leaderId)
            .set(newLeader)
            .then(() => {
                //setShowAddedToast(true);
            })
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
            .finally(() => {
                leaderAdded();
            })
    }

    // TODO: Catch if don't have enough data or if OG number already exists'
    // TODO: Toast isn't working

    return (
        <div className="SubmitLeader">
            <h5>Add New Entry</h5>
            <h6>
                <i>Enter at the name, number of cases, and purchaser type before submitting!</i>
            </h6>
            <div>
                <div className="row mt-3">
                    <div className="col">
                        <h5>Enter Name of Purchaser</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input id="purchaser-name"
                               type="string"
                            //onChange={input => nameAdded(input)}
                               className="form-control"
                               aria-describedby="purchaser-name"
                               placeholder="Enter name to be displayed"/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <h5>Enter Number of Cases Purchased</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <input id="case-number"
                               type="number"
                            //onChange={input => numberAdded(input)}
                               className="form-control"
                               aria-describedby="wine-number"
                               placeholder="Enter as an integer (E.g. '40')"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h5>What is the category of purchaser?</h5>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <button type="button"
                                className={`btn ${purchaserType === "Individual" ? 'btn-info' : 'btn-outline-info'}`}
                                onClick={() => setPurchaserType("Individual")}>
                            Individual
                        </button>
                    </div>
                    <div className="col pl-0">
                        <button type="button"
                                className={`btn ${purchaserType === "Group" ? 'btn-info' : 'btn-outline-info'}`}
                                onClick={() => setPurchaserType("Group")}>
                            Group
                        </button>
                    </div>
                </div>
            </div>
            <button type="button"
                    className={"btn btn-info"}
                    onClick={() => submitLeader()}>
                SUBMIT
            </button>
        </div>
    );

}

export default SubmitLeader;
