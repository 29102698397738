import React from "react";
import PropTypes from "prop-types";
import "./TradeOverview.css";

function TradeOverview(props) {

    return (
        <div className="trade-overview">
            <div className="row text-center">
                {(props.showAvailable === undefined || props.showAvailable) && (
                    <div className="col">
                        <h5>Number Offered</h5>
                        <h6>{
                            props.wine.bottlesAvailable
                                ? props.wine.bottlesAvailable
                                : "None"
                        }</h6>
                    </div>
                )}
                {(props.showWanted === undefined || props.showWanted) && (
                    <div className="col">
                        <h5>Number Wanted</h5>
                        <h6>{
                            props.wine.bottlesWanted
                                ? props.wine.bottlesWanted
                                : "None"
                        }</h6>
                    </div>
                )}
            </div>
        </div>
    )
}
TradeOverview.propTypes = {
    wine: PropTypes.object.isRequired,
    showAvailable: PropTypes.bool,
    showWanted: PropTypes.bool,
};

export default TradeOverview;
