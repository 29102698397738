import React, {useState} from "react";
import PropTypes from "prop-types";
import api from "./api";
import ErrorMessage from "./ErrorMessage";
import "./EmailContactModalDialog.css"
import "./Common.css"

function EmailContactModalDialog(props) {

    const [fillAllFieldsError, setFillAllFieldsError] = useState(false);
    const [genericError, setGenericError] = useState(false);

    function sendEmail() {
        const fromEmail = document.getElementById("from-email").value;
        const subject = document.getElementById("subject").value;
        const message = document.getElementById("message").value;

        if (!fromEmail || !message) {
            setFillAllFieldsError(true);
            return;
        }

        api().collection("mail")
            .add({
                to: [props.toEmail],
                from: fromEmail,
                replyTo: fromEmail,
                message: {
                    subject: subject,
                    text: message,
                    // added below line to send to ljccloud
                }
            })
            .then(() => props.onSuccess())
            .catch(() => setGenericError(true))
    }

    function doNothing(event) {
        event.stopPropagation();
    }

    return (
        <div className="EmailContactModalDialog" onClick={props.onOutsideClick}>
            <div className="card" onClick={doNothing}>
                <h4 className="title">{props.title}</h4>
                <input id="from-email" type="email" placeholder="Your email address"/>
                <input id="subject" type="text" placeholder="Email subject"/>
                <textarea id="message" placeholder="Your message"/>
                {fillAllFieldsError && (
                    <ErrorMessage message="Please fill out all fields"/>
                )}
                {genericError && (
                    <ErrorMessage message={
                        "Sorry, there was an error sending your message. "
                        + "Please check your Internet connection and try again."
                    }/>
                )}
                <button
                    type="button"
                    className="primary-button"
                    onClick={sendEmail}>
                    Send email
                </button>
            </div>
        </div>
    );
}
EmailContactModalDialog.propTypes = {
    title: PropTypes.string.isRequired,
    toEmail: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
}

export default EmailContactModalDialog;
