import React from "react";
import PropTypes from "prop-types";
import "./WineItem.css";
import "./Common.css";
import WineOverview from "./WineOverview";
import {Link} from "react-router-dom";

function WineItem(props) {

    return (
        <div id={props.wine.number}
             className="WineItem card">
            <Link to={`/${props.wine.number}`} className="card-item-title-link">
                {props.wine.name}
            </Link>
            <h2>  </h2>
            <WineOverview wine={props.wine}/>
        </div>
    )
}
WineItem.propTypes = {
    wine: PropTypes.object.isRequired,
};

export default WineItem;
