import React, {useState} from "react";
import PropTypes from "prop-types";
import "./LeaderItem.css";
import api from "./api";
import ModalDialog from "./ModalDialog";


function EditLeaderItemModalDialog(props) {

    function onSaveClick() {
        const numCasesInput = document.getElementById("numCases");
        props.onSaveClick(parseInt(numCasesInput.value));
    }

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="EditLeaderItemModalDialog"
             onClick={() => props.onOutsideClick()}>
            <div className="card" onClick={doNothing}>
                <h4>Update your number of cases</h4>
                <i>Please only edit this if you are {props.purchaserName}</i>
                <h5>Enter your new number of cases</h5>
                <input
                    id="numCases"
                    type="number"
                    min="1"
                    defaultValue={props.casesBought}/>
                <div className="button-container">
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => onSaveClick()}>
                        Save changes
                    </button>
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => props.onCloseClick()}>
                        Nevermind
                    </button>

                </div>
            </div>
        </div>
    );
}
EditLeaderItemModalDialog.propTypes = {
    purchaserName: PropTypes.string.isRequired,
    casesBought: PropTypes.number.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
}

function LeaderItem(props) {

    const [itemCount, setItemCount] = useState(props.leader.casesBought);
    // const [errorUpdatingCount, setErrorUpdatingCount] = useState(false);
    const [editLeaderItem, setEditLeaderItem] = useState(false);
    const [errorUpdatingLeader, setErrorUpdatingLeader] = useState(false);

    function onSaveNumCases(numCases) {
        setItemCount(numCases);
        setEditLeaderItem(false);
        const leaderId = props.leader.leaderId;
        api().collection("leaders")
            .doc(leaderId)
            .update({
                casesBought: numCases,
            })
            .catch(() => {
                setErrorUpdatingLeader(true);
            })
    }


    // function incrementCount(diff) {
    //     const originalCount = itemCount;
    //     setItemCount(itemCount + diff)
    //     api()
    //         .collection('leaders')
    //         .doc(props.leader.leaderId)
    //         .set({
    //             casesBought: firebase.firestore.FieldValue.increment(diff)
    //         }, {
    //             merge: true
    //         })
    //         .catch(error => {
    //             setErrorUpdatingCount(true)
    //             setItemCount(originalCount)
    //             console.error(error.message);
    //         })
    // }

    return (
        <div id={props.leader.leaderId}
             className="LeaderItem card">
                <div className="row text-center">
                    <div className="col">
                        <h5>{props.leader.purchaserName}</h5>
                    </div>
                    <div className="col">
                        <h5>{itemCount}</h5>
                    </div>
                    <div className="col">
                        {/*<span className="vote-arrow"*/}
                        {/*      onClick={() => incrementCount(1)}>*/}
                        {/*    ⬆️*/}
                        {/*</span>*/}
                        {/*<span className="vote-arrow"*/}
                        {/*      onClick={() => incrementCount(-1)}>*/}
                        {/*    ⬇️*/}
                        {/*</span>*/}
                        <div className="link-button" onClick={() => setEditLeaderItem(true)}>
                            Update
                        </div>
                    </div>
                </div>
            {editLeaderItem && (
                <EditLeaderItemModalDialog
                    purchaserName={props.leader.purchaserName}
                    casesBought={props.leader.casesBought}
                    onSaveClick={casesBought => onSaveNumCases(casesBought)}
                    onOutsideClick={() => setEditLeaderItem(false)}
                    onCloseClick={() => setEditLeaderItem(false)} />
            )}
            {errorUpdatingLeader && (
                <ModalDialog
                    title="Problem updating count"
                    body={
                        "Sorry about that! It looks like there was an error "
                        + "updating the leaderboard. Please make sure you're "
                        + "connected to the Internet and try again. If there's "
                        + "still a problem, please contact us!"
                    }
                    buttonText="OK"
                    buttonAction={() => setErrorUpdatingLeader(false)}
                    outsideClickAction={() => setErrorUpdatingLeader(false)} />
            )}
        </div>
    )
}
LeaderItem.propTypes = {
    leader: PropTypes.object.isRequired,
};

export default LeaderItem;