import React from "react";
import PropTypes from "prop-types";
import "./WineComment.css";


function WineComment(props) {


    return (
        <div>
            <div className="wine-comment-container card">
                <div className="wine-comment-wineName row">
                    { props.wineName && (
                        <div className="col">
                            <b>{props.wineName}</b>
                        </div>
                    )}
                </div>
                <div className="wine-comment-text row">
                    <div className="col">
                        {props.comment.commentText}
                    </div>
                </div>
                <div className="wine-comment-footer d-flex">
                    <div className="flex-fill">
                        <i>{props.comment.userName}</i>
                    </div>
                    <div className="flex-fill">
                        <i>{props.comment.timestamp.toDate().toLocaleString()}</i>
                    </div>
                </div>
            </div>
        </div>
    )
}

WineComment.propTypes = {
    comment: PropTypes.object.isRequired,
    wineName: PropTypes.string
};

export default WineComment;