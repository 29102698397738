import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "./AddField.css";
import api from "./api";
import LoadingSpinner from "./LoadingSpinner";
import ErrorMessage from "./ErrorMessage";
import {logPageView} from "./analytics";

// will need to know wine number and which field to change

function AddField () {

    const ERROR_LOADING = "error_loading";
    const ERROR_NO_POST = "error_no_post";
    const [wine, setWine] = useState(null);
    const [error, setError] = useState(null);

    let {og, fieldName, dataType, promptText} = useParams();

    const wineNum = parseInt(og);
    const prompt = promptText.replace(/\+/g, " ");
    console.log("param ogNumber is"+wineNum);
    console.log("addfiled param fieldName is"+ fieldName);
    console.log("addfield param prompttext is"+ promptText);
    console.log("addfield param prompt is"+ prompt);
    console.log("addfield dataType is"+ dataType);

    useEffect(() => {
        logPageView("add_field");
    },[]);

    useEffect(() => {
        api().collection("wines")
            .where("number", "==", wineNum)
            .get()
            .then(querySnap => {
                if (querySnap.empty) {
                    console.log("hit db in addnumField 1");
                    setError(ERROR_NO_POST);
                } else {
                    console.log("hit db in addNumField 2");
                    console.log("wine Id from querysnap is wine.id"+ querySnap.docs[0].id);
                    // wineId = querySnap.docs[0].id;
                    setWine(querySnap.docs[0].data());
                }
            })
            .catch(() => {
                console.log("hit db in addNumField 3");
                setError(ERROR_LOADING);
            })
    }, [wineNum]);

    // const qprTextBox = document.getElementById("qpr-guess");
    // const usernameTextBox = document.getElementById("username");
    //
    // function onQprAdded(qprGuess) {
    //     qpr = qprTextBox.value ? parseInt(qprGuess.value) : null;
    // }
    //
    // function onUsernameAdded(userName) {
    //     user = usernameTextBox.value ? userName.value : null;
    // }

    function submitField() {
        const fieldBox = document.getElementById("field-value");
        let fieldValue = null;
        if (dataType === "number") {
            fieldValue = fieldBox.value ? parseInt(fieldBox.value) : null;
        } else {
            fieldValue = fieldBox.value ? fieldBox.value : null;
        }
        console.log("field value is"+ fieldValue);
        let dict = null;
        if (fieldName === "casePrice") {
            const bottlePrice = parseFloat((fieldValue/12).toFixed(1));
            dict = {
                [fieldName]: fieldValue,
                bottlePrice: bottlePrice
            };
        } else {
            dict = {
                [fieldName]: fieldValue,
            }
        }
        saveFieldToDb(dict);
        console.log("field value is"+ fieldValue);
    }

    function saveFieldToDb(dict) {
        api().doc(`wines/${wine.wineId}`)
            .update(dict)
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
            .finally(() => {
                //did this instead of useHistory bc want to reload so winelist w new info is pulled from db
                window.location.href = `/${wineNum}`;
            })
    }

    return (
        <div className="AddField">
            {!error && !wine && (
                <LoadingSpinner/>
            )}
            {error && error === ERROR_LOADING && (
                <ErrorMessage message={
                    "There was an error loading the page! " +
                    "Please confirm you are connected to the Internet and " +
                    "try again."
                }/>
            )}
            {error && error === ERROR_NO_POST && (
                <ErrorMessage message={
                    "It doesn't look like " +
                    "there's a wine here. Please go back to the wine list page " +
                    "and try clicking the link again."
                }/>
            )}
            {!error && wine && (
                <div className="card">
                    <h5>{prompt} for OG {wine.number}</h5>
                    <input id="field-value"
                           type={dataType}
                           aria-describedby="field-value"
                           placeholder=""/>
                    <button type="button"
                            className="primary-button"
                            onClick={() => submitField()}>
                        Submit
                    </button>
                </div>
            )}
        </div>
    )
}

export default AddField;
