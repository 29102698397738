import React, {useEffect, useState,} from "react";

import { v4 as uuidv4 } from 'uuid';
import "./LeaderCommentFeed.css";
import LeaderComment from "./WineComment.js";
import api from "./api";
import {Helmet} from "react-helmet";
import * as firebase from 'firebase/app'

import LoadingSpinner from "./LoadingSpinner";

function LeaderCommentFeed() {

    const [comments, setComments] = useState(null);
    const [errorLoading, setErrorLoading] = useState(false);
    const [errorSaving, setErrorSaving] = useState(false);
    const commentPlaceholder = `Add a comment`;

    useEffect(() => {
        const unsubscribe = api().collection("comments")
            .where("type","==","Leader")
            // .where('reportedTimestamp','==', null)
            .orderBy('timestamp','desc')
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    console.log("hit db in leadercommentfeed 1");
                    setComments([]);
                } else {
                    console.log("hit db in leadercommentfeed 2");
                    setComments(querySnap.docs.map(doc => {
                        const comment = doc.data();
                        return comment;
                    }));
                }
            }, error => {
                console.log("hit db in leadercommentfeed 3");
                console.error(`Error loading comments: ${error}`);
                setErrorLoading(true);
            })

        return () => {
            unsubscribe()
        }
    },[]);

    function getLeaderComments() {
        return comments.map(comment =>
            <LeaderComment key={comment.commentId} comment={comment}/>)
    }

    function submitComment() {
        // this.setState({
        //     errorLoading: false,
        //     errorSaving: false
        // })
        const commentId = uuidv4();
        const Timestamp = firebase.firestore.Timestamp;
        const commentTextBox = document.getElementById("new-comment");
        const userNameTextBox = document.getElementById("new-comment-username");

        const comment = commentTextBox.value;
        console.log("comment text is "+comment);
        let userName = userNameTextBox.value;
        if (userName === "") userName = "Anonymous User";
        console.log("username is "+ userName);

        if (!comment) {
            return;
        }

        const newComment = {
            key: commentId,
            type: "Leader",
            commentId: commentId,
            // userId: currentUserId(),
            commentText: comment,
            userName: userName,
            timestamp: Timestamp.now(),
            reportedTimestamp: null,
            thumbsUpCount: 0,
        };


        api()
            .collection('comments')
            .doc(commentId)
            .set(newComment)
            .then(() => {
                commentTextBox.value = "";
                userNameTextBox.value = "";
                console.log("comment text box is "+ commentTextBox.value);
                console.log("name text is "+userNameTextBox.value);
            })
            .catch(error => {
                console.error(error.message);
                setErrorSaving(true);
            })
    }

    return (
        <div className="LeaderCommentFeed">
            <Helmet>
                <title>
                    Comments on the leaderboard
                </title>
                <meta
                    name="description"
                    content={
                        "Comments that have been made about leader rankings"
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com"
                />
            </Helmet>
            <div className="comment-feed-container">
                <div className="comment-feed">
                    {(comments === null) && (
                        <LoadingSpinner/>
                    )}
                    {comments !== null && !comments.length && (
                        <p>
                            Be the first to comment on the leaderboard by
                            using the form below.
                        </p>
                    )}
                    {comments !== null && comments.length > 0 && getLeaderComments()}
                </div>
                <div className="new-comment-container">
                    <div className="new-comment">
                        <div className="row">
                            <div className="col">
                                <textarea
                                    id="new-comment"
                                    className="form-control"
                                    rows="2"
                                    placeholder={commentPlaceholder}/>
                                <input
                                    type="text"
                                    id="new-comment-username"
                                    className="form-control mt-2"
                                    placeholder="Username to show with comment"/>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <button type="button"
                                        className="primary-button"
                                        onClick={() => submitComment()}>
                                    Submit your comment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderCommentFeed;