import React, {useEffect, useState} from "react";
import api from "./api";
import UpcomingWineItem from "./UpcomingWineItem";
import LoadingSpinner from "./LoadingSpinner";
import "./WineList.css";
import "./Common.css"
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {logPageView} from "./analytics";

function Upcoming() {

    const [wines, setWines] = useState(null);

    useEffect(() => {
        logPageView("upcoming_list");
    },[]);

    useEffect(() => {
        const unsubscribe = api().collection("upcoming")
            .orderBy("number", "asc")
            .onSnapshot(querySnap => {
                if (querySnap.empty) {
                    setWines([]);
                    console.log("hit db in upcominglist 1");
                } else {
                    console.log("hit db in upcominglist 2");
                    setWines(querySnap.docs.map(doc => {
                        let wine = doc.data();
                        return wine;
                    }));
                }
            }, error => {
                console.log("hit db in upcominglist 3");
                console.error(`Error loading upcoming wines: ${error}`);
            })

        return () => {
            unsubscribe()
        }
    },[]);

    function getWineItems() {
        return wines.map(wine =>
            <UpcomingWineItem key={wine.upcomingId} wine={wine}/>)
    }

    return (
        <div className="WineList">
            <Helmet>
                <title>
                    List of Upcoming de Négoce Releases
                </title>
                <meta
                    name="description"
                    content={
                        "Updatable ist of wines that have " +
                        "been registered with COLA and will be released under the de Négoce label."
                    }
                />
                <link
                    rel="canonical"
                    href="https://denegociants.com/upcoming"
                />
            </Helmet>
            <Link to="/addupcoming" className="primary-button">
                Add a New Upcoming Wine
            </Link>
            <div className="WineList card">
                <p>
                    These wines have been registered with the alcohol bureau by De Negoce but have not yet been offered for sale.
                </p>
            </div>
            <div className="list-container">
                {!wines && (
                    <LoadingSpinner/>
                )}
                {wines && getWineItems()}
            </div>
        </div>
    )
}

export default Upcoming;

