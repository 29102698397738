import React, {useEffect, useState} from "react";
import Select from 'react-select';
import "./AddTrade.css";
import "./Common.css";
import api from "./api";
import { v4 as uuidv4 } from 'uuid';
import * as firebase from "firebase";
import ModalDialog from "./ModalDialog";
import ErrorMessage from "./ErrorMessage";
import {logPageView} from "./analytics";
import {stateList} from "./Common";

class WineDoesNotExistError extends Error {
    constructor() {
        super("No wine with that number exists");
    }
}

function AddTrade() {

    const [willShip, setWillShip] = useState(null);
    const [geoState, setGeoState] = useState(null);
    const [offerType, setOfferType] = useState(null);
    const [emptyFieldsError, setEmptyFieldsError] = useState(false);
    const [
        wineDoesNotExistError,
        setWineDoesNotExistError
    ] = useState(false);
    const [
        genericError,
        setGenericError
    ] = useState(false);
    let  wineNumSubmitted = null;

    useEffect(() => {
        logPageView("add_trade");
    },[]);

    function tradeAddedComplete() {
        if (wineNumSubmitted) {
            window.location.href = "/wineexchange/"+wineNumSubmitted;
        } else {
            window.location.href = "/wineexchange"
        }
    }

    function getWineId(wineNumber) {
        return api().collection("wines")
            .where("number","==", wineNumber)
            .limit(1)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.empty) {
                    throw new WineDoesNotExistError();
                }
                const wineDoc = querySnapshot.docs[0].data();
                return wineDoc.wineId;
            })
    }

    // Todo: It'd be better to do this increment/decrement in a cloud function.
    function submitWineLevelNumChange(wineId, numBottles) {
        let dbField;
        if (offerType === "Wanted") {
            dbField = "bottlesWanted";
        } else {
            dbField = "bottlesAvailable";
        }

        return api().doc(`wines/${wineId}`)
            .update({
                // userId: currentUserId(),
                [dbField]: firebase.firestore.FieldValue.increment(numBottles)
            });
    }

    function submitTrade() {
        const tradeId = uuidv4();
        const usernameTextBox = document.getElementById("username");
        const numberTextBox = document.getElementById("wine-number");
        const cityTextBox = document.getElementById("city");
        // const stateTextBox = document.getElementById("state");
        // const countryTextBox = document.getElementById("country");
        const emailTextBox = document.getElementById("email");
        const numBottlesTextBox = document.getElementById("bottle-number");

        const submitUsername = usernameTextBox.value ? usernameTextBox.value : null;
        const submitNumber = numberTextBox.value ? parseInt(numberTextBox.value) : null;
        wineNumSubmitted = submitNumber;
        const submitCity = cityTextBox.value ? cityTextBox.value : null;
        // const submitState = stateTextBox.value ? stateTextBox.value : null;
        // const submitCountry = countryTextBox.value ? countryTextBox.value : null;
        const submitEmail =  emailTextBox.value ? emailTextBox.value : null;
        const submitBottleNumber = numBottlesTextBox.value ? parseInt(numBottlesTextBox.value) : null;

        if (!(submitUsername && submitNumber && submitBottleNumber && submitEmail)) {
            setEmptyFieldsError(true);
            return;
        }

        getWineId(submitNumber)
            .then(wineId => {
                const newTrade = {
                    key: tradeId,
                    tradeId: tradeId,
                    wineId: wineId,
                    wineNumber: submitNumber,
                    username: submitUsername,
                    // userId: currentUserId(),
                    city: submitCity,
                    state: geoState.value,
                    userEmail: submitEmail,
                    numBottles: submitBottleNumber,
                    willShip: willShip,
                    offerType: offerType,
                    timestamp: firebase.firestore.Timestamp.now(),
                };
                return api()
                    .collection('trades')
                    .doc(tradeId)
                    .set(newTrade)
                    .then(() => {
                        return submitWineLevelNumChange(
                            wineId,
                            submitBottleNumber
                        );
                    });
            })
            .then(() => {
                tradeAddedComplete();
            })
            .catch(error => {
                if (error instanceof WineDoesNotExistError) {
                    setWineDoesNotExistError(true);
                } else {
                    setGenericError(true);
                }
            });
    }

    function onAddWineClick() {
        setWineDoesNotExistError(false)
        window.open("/add");
    }

    function onOutsideAddWineClick() {
        setWineDoesNotExistError(false)
    }

    function closeGenericErrorMessage() {
        setGenericError(false);
    }

    // TODO: Toast isn't working

    return (
        <div className="AddTrade">
            <h5>Enter your possible exchange</h5>
            <div className="form">
                <h6>
                    <i>Must enter all fields before submitting!</i>
                </h6>
                <div className="row">
                    <div className="label">OG Number</div>
                    <input id="wine-number"
                           type="number"
                           aria-describedby="wine-number"
                           placeholder=""/>
                </div>
                <div className="row">
                    <div className="label">Have wine to trade or want wine?</div>
                    <div className="toggle-buttons">
                        <button type="button"
                                className={`btn ${offerType === "Available" ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setOfferType("Available")}>
                            I Have Wine
                        </button>
                        <button type="button"
                                className={`btn ${offerType === "Wanted" ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setOfferType("Wanted")}>
                            I Want Wine
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="label">How many bottles do you have/want?</div>
                    <input id="bottle-number"
                           type="number"
                           aria-describedby="bottle-number"
                           placeholder=""/>
                </div>
                <div className="row">
                    <div className="label">Willing to ship or local only?</div>
                    <div className="toggle-buttons">
                        <button type="button"
                                className={`btn ${willShip === true ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setWillShip(true)}>
                            I'm open to shipping
                        </button>
                        <button type="button"
                                className={`btn ${willShip === false ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setWillShip(false)}>
                            Local only
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="label">Your name or username</div>
                    <input id="username"
                           type="string"
                           aria-describedby="username"
                           placeholder=""/>
                </div>
                <div className="row">
                    <div className="label">City</div>
                    <input id="city"
                           type="string"
                           aria-describedby="city"
                           placeholder="e.g. San Francisco"/>
                </div>
                {/*<div className='row'>*/}
                {/*    <div className='label'>State</div>*/}
                {/*    <input id='state'>*/}
                {/*        stateList.map(item => (*/}
                {/*            <option key={item} value={item}>*/}
                {/*                {item}*/}
                {/*            </option>*/}
                {/*        ))}*/}
                {/*    </input>*/}
                {/*</div>*/}
                <div className="row">
                    <div className="label">State</div>
                    <Select
                        className="react-select"
                        onChange={setGeoState}
                        options={stateList.map(t=>({value: t, label: t}))}
                        placeholder="None Selected"
                    />
                    {/*<input id="state"*/}
                    {/*       type="string"*/}
                    {/*       aria-describedby="state"*/}
                    {/*       placeholder="2 letter abbreviation, e.g. TX"/>*/}
                </div>
                <div className="row">
                    <div className="label">
                        Email address for contact <br/>
                        <i>Email will never be shown on website</i>
                    </div>
                    <input id="email"
                           type="string"
                           aria-describedby="email"
                           placeholder="winedrinker@gmail.com"/>
                </div>
                {emptyFieldsError && (
                    <ErrorMessage message={
                        "Please fill out all fields before submitting."
                    }/>
                )}
                <button type="button"
                        className="submit primary-button"
                        onClick={() => submitTrade()}>
                    SUBMIT
                </button>
            </div>
            {wineDoesNotExistError && (
                <ModalDialog
                    title="That wine isn't in our system yet"
                    body={
                        "Sorry about that! The wine with the OG number "
                        + "you entered doesn't exist quite yet. Would you mind "
                        + "adding it for us? Please click the 'Add wine' button "
                        + "below. Don't worry; we'll open a new tab so you don't "
                        + "lose your place here."
                    }
                    buttonText="Add wine"
                    buttonAction={() => onAddWineClick()}
                    outsideClickAction={() => onOutsideAddWineClick()}/>
            )}
            {genericError && (
                <ModalDialog
                    title="Hmm, something went wrong"
                    body={
                        "Sorry about that! Something went wrong, but we're not "
                        + "sure what. Please contact us to let us know!"
                    }
                    buttonText="Ok"
                    buttonAction={() => closeGenericErrorMessage()}
                    outsideClickAction={() => closeGenericErrorMessage()}/>
            )}
        </div>
    );

}

export default AddTrade;






