import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import "./Header.css";
import EmailContactModalDialog from "./EmailContactModalDialog";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import api from "./api";
import firebase from "firebase/app";
import firebaseApp from "./firebaseApp";
import "firebase/auth";


function Header() {

    const location = useLocation();
    const [showContactUs, setShowContactUs] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);


    const uiConfig = {
        signInFlow:'popup',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // requireDisplayName: false
        ],
        callbacks: {
            // signInSuccessWithAuthResult: () => false
            signInSuccessWithAuthResult: () => {
                createUserInDb();
                // createHoldingPage();
                return false;
            }
        }
    };
    firebaseApp();

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    function createUserInDb() {
        const user = firebase.auth().currentUser;
        api().doc(`users/${user.uid}`)
            .set({
                key: user.uid,
                timestamp: firebase.firestore.Timestamp.now(),
                userId: user.uid,
                userName: user.displayName,
                userEmail: user.email,
            },{merge: true})
            .catch(error => {
                console.error(error.message);
                // setErrorSaving(true);
            })
    }

    // function createHoldingPage() {
    //     const user = firebase.auth().currentUser;
    //     api().collection("holdings")
    //         .doc(user.uid)
    //         //make sure holdings doc for user exists
    //         .set({
    //             key: user.uid,
    //             userId: user.uid,
    //             userName: user.displayName
    //         },{merge : true})
    //         .catch(error => {
    //             console.error(error.message);
    //             // setErrorSaving(true);
    //         })
    // }

    function isWineList() {
        return location.pathname.match(/^\/[0-9]*$/);
    }

    function isWineExchange() {
        return location.pathname.match(/^\/wineexchange(\/[0-9].*\/.*)?$/);
    }

    // function isLeaderboard() {
    //     return location.pathname === "/leaderboard";
    // }

    // function isEvents() {
    //     return location.pathname === "/events";
    // }

    // function isUpcoming() {
    //     return location.pathname === "/upcoming";
    // }

    function isHolding() {
        return location.pathname === "/holdings";
    }

    // function isFeedback() {
    //     return location.pathname === "/feedback";
    // }

    function isAbout() {
        return location.pathname === "/about";
    }

    return (
        <div className="Header">
            <div className="logo">
                <span className="brand">
                    <Link to="/">
                          de Négociants
                    </Link>
                </span>
            </div>
            <div className="navigation">
                <Link to="/" className={`navigation-item ${
                    isWineList() ? "selected" : ""
                }`}>
                    Wines
                </Link>
                <Link to="/wineexchange" className={`navigation-item ${
                    isWineExchange() ? "selected" : ""
                }`}>
                    Wine exchange
                </Link>
                <Link to="/holdings" className={`navigation-item ${
                    isHolding() ? "selected" : ""
                }`}>
                    Holdings
                </Link>
                {/*<Link to="/leaderboard" className={`navigation-item ${*/}
                {/*    isLeaderboard() ? "selected" : ""*/}
                {/*}`}>*/}
                {/*    Leaderboard*/}
                {/*</Link>*/}
                {/*<Link to="/feedback" className={`navigation-item ${*/}
                {/*    isFeedback() ? "selected" : ""*/}
                {/*}`}>*/}
                {/*    Feedback*/}
                {/*</Link>*/}
                <Link to="/about" className={`navigation-item ${
                    isAbout() ? "selected" : ""
                }`}>
                    About
                </Link>
                <div className="navigation-item"
                     onClick={() => setShowContactUs(true)}>
                    Contact us
                </div>
                {!isSignedIn && <div className="navigation-item"
                        onClick={() => {
                            console.log("login was clicked");
                            setShowLogin(true)
                        }}>
                    Login
                </div>}
                {isSignedIn && <div className="navigation-item"
                        onClick={() => firebase.auth().signOut()}>
                    Logout
                </div>}
            </div>
            {showContactUs && (
                <EmailContactModalDialog
                    title="Contact deNégociants.com"
                    toEmail="denegociants@gmail.com"
                    onSuccess={() => setShowContactUs(false)}
                    onOutsideClick={() => setShowContactUs(false)}/>
            )}
            {showLogin && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>}
        </div>
    )
}

export default Header;
