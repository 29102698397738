import React from "react";
import PropTypes from "prop-types";
//import "./WineOverview.css";

function WineOverview(props) {

    function getWouldDrinkPercent() {
        let yesCount = props.wine.wouldDrink.reduce(function(a, b){
            return a + b;
        }, 0);
        const totCount = props.wine.wouldDrink.length;
        const drinkRatio = (yesCount*100/totCount).toFixed(0)
        return drinkRatio+"%";
    }

    function getBottlePrice() {
        let numBottles = 12;
        if (props.wine.bottlesPerCase) numBottles = props.wine.bottlesPerCase;
        let tempPrice = props.wine.casePrice/numBottles;
        if (tempPrice%1 === 0) {
            return tempPrice;
        } else {
            return tempPrice.toFixed(2);
        }
    }

    function getBottleShockResult() {
        if (!props.wine.bottleDate) return "Unknown";
        let monthsNeeded;
        if (props.wine.type === "Red") {
            monthsNeeded = 4;
        } else {
            monthsNeeded = 2;
        }

        const dateParts = props.wine.bottleDate.split('-');
        const dateBottled = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        const todayDate = new Date();
        const targetDate = dateBottled.setMonth(dateBottled.getMonth() + monthsNeeded);
        if (todayDate > targetDate) {
            return "Yes"
        } else {
            return "No"
        }
    }

    return (
        <div className="wine-overview">
            <div className="row text-center">
                <div className="col">
                    <h5>Would Drink:</h5>
                    {/*<h4>{this.safetyStatus()}</h4>*/}
                    {(props.wine.wouldDrink.length !== 0) && (<h6>{getWouldDrinkPercent()}</h6>)}
                    {(props.wine.wouldDrink.length === 0) && (<h6>No info</h6>)}
                </div>
                <div className="col">
                    <h5>Source Guess:</h5>
                    <h6>{props.wine.possibleSource ? props.wine.possibleSource : "None"}</h6>
                </div>
                {/*<div className="col">*/}
                {/*    <h5>Drinking Window:</h5>*/}
                {/*    {(!props.wine.drinkBeginningYear && !props.wine.drinkEndingYear) && (<h6>No info</h6>)}*/}
                {/*    {(!props.wine.drinkBeginningYear && props.wine.drinkEndingYear) && (<h6>No info - {props.wine.drinkEndingYear}</h6>)}*/}
                {/*    {(props.wine.drinkBeginningYear && !props.wine.drinkEndingYear) && (<h6>{props.wine.drinkBeginningYear} - No info</h6>)}*/}
                {/*    {(props.wine.drinkBeginningYear && props.wine.drinkEndingYear) && (<h6>{props.wine.drinkBeginningYear} - {props.wine.drinkEndingYear}</h6>)}*/}
                {/*</div>*/}
                <div className="col">
                    <h5>Past Bottle Shock</h5>
                    <h6>{getBottleShockResult()}</h6>
                </div>
                <div className="col">
                    <h5>Base Price Per Bottle</h5>
                    <h6>{props.wine.casePrice ? getBottlePrice() : "Unknown"}</h6>
                </div>
                <div className="col">
                    <h5>Retail Price Per Bottle</h5>
                    <h6>{props.wine.retailPrice ? ('$'+props.wine.retailPrice) : "Unknown"}</h6>
                </div>
                {/*make these links to specific wine trade page*/}
                <div className="col">
                    <h5>Trades Offered</h5>
                    <h6>{(props.wine.bottlesWanted || props.wine.bottlesAvailable) ? "Yes" : "None"}</h6>
                </div>
            </div>
        </div>
        )
    }

WineOverview.propTypes = {
    wine: PropTypes.object.isRequired,
};

export default WineOverview;