import React, {useState} from "react";
import PropTypes from "prop-types";
import "./RatingsOverview.css";

function AddReviewModalDialog(props) {

    const [reviewType, setReviewType] = useState(null);

    function doNothing(event) {
        // Todo: this is meant to swallow a click, but seems hacky.
        event.stopPropagation();
    }

    return (
        <div className="AddReviewModalDialog"
             onClick={() => props.onOutsideClick()}>
            <div className="card" onClick={doNothing}>
                <h5>Enter Type of Review You'd Like to Leave</h5>
                <div className="row">
                    <div className="toggle-buttons">
                        <button type="button"
                                className={`btn ${reviewType === 0 ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setReviewType(0)}>
                            Ratings Only
                        </button>
                        <button type="button"
                                className={`btn ${reviewType === 2 ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setReviewType(2)}>
                            Tasting Note Only
                        </button>
                        <button type="button"
                                className={`btn ${reviewType === 1 ? 'btn-info' : 'btn-outline-info'} toggle-button`}
                                onClick={() => setReviewType(1)}>
                            Both
                        </button>
                    </div>
                </div>
                <div className="button-container">
                    <button
                        type="button"
                        className="primary-button"
                        onClick={() => props.onSaveClick(reviewType)}>
                        Start My Review
                    </button>
                </div>
            </div>
        </div>
    );
}
AddReviewModalDialog.propTypes = {
    onSaveClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired,
}

function RatingsOverview(props) {
    const [showModal, setShowModal] = useState(false);

    // function getWouldDrinkRatio() {
    //     let yesCount = props.wine.wouldDrink.reduce(function(a, b){
    //         return a + b;
    //     }, 0);
    //     const totCount = props.wine.wouldDrink.length;
    //     return yesCount+"/"+totCount;
    // }

    function getWouldDrinkPercent() {
        let yesCount = props.wine.wouldDrink.reduce(function(a, b){
            return a + b;
        }, 0);
        const totCount = props.wine.wouldDrink.length;
        const drinkRatio = (yesCount*100/totCount).toFixed(0)
        return drinkRatio+"%";
    }

    function getQprAverage() {
        let sum = props.wine.qprs.reduce(function(a, b){
            return a + b;
        }, 0);

        let count = props.wine.qprs.filter(qpr => qpr !== null).length;
        return sum/count;
    }

    function getQprRatio() {
        let sum = props.wine.qprs.reduce(function(a, b){
            return a + b;
        }, 0);
        let count = props.wine.qprs.filter(qpr => qpr !== null).length;
        return (sum/count)/props.wine.bottlePrice;
    }

    function getNumRatingAverage() {
        let sum = props.wine.numRatings.reduce(function(a, b){
            return a + b;
        }, 0);
        let count = props.wine.numRatings.filter(qpr => qpr !== null).length;
        return sum/count;
    }

    function onSaveClick(reviewType) {
        window.location.href = `/addrating/${props.wine.number}/${reviewType}`;
    }


    //need to put safety in this by having a null check
    return (
        <div className="ratings-overview">
            <div className ="row text-center">
                <div className = "col"><h5>Ratings Overview</h5></div>
            </div>
            <div className="row text-center">
                <div className="col">
                    <h6>Would Drink Again:</h6>
                    {/*<h4>{this.safetyStatus()}</h4>*/}
                    <h7>{(props.wine.wouldDrink.length !== 0) ? getWouldDrinkPercent() : "Need ratings"}</h7>
                </div>
                <div className="col">
                    <h6>Estimated Value</h6>
                    <h7>{(props.wine.qprs.filter(qpr => qpr !== null).length !== 0) ? `$${getQprAverage().toFixed(0)}` : "Need ratings"}</h7>
                </div>
                <div className="col">
                    <h6>Estimated QPR</h6>
                    <h7>{((props.wine.qprs.filter(qpr => qpr !== null).length !== 0) && (props.wine.bottlePrice)) ? getQprRatio().toFixed(1) : "Need ratings"}</h7>
                </div>
                <div className="col">
                    <h6>Rating</h6>
                    <h7>{props.wine.numRatings.filter(rating => rating !== null).length !== 0 ? getNumRatingAverage().toFixed(1): "Need ratings"}</h7>
                </div>
                {/*<div className="col">*/}
                {/*    <h4>IsAvailable</h4>*/}
                {/*    <h4>{this.waitTime()}</h4>*/}
                {/*</div>*/}
            </div>
            <div className ="row text-center">
                <div className = "col"><i>Based on {props.wine.wouldDrink.length} reviews</i></div>
            </div>
            <div className="row text-center">
                <div className="col">
                <button type="button"
                        className="btn btn-outline-info"
                        onClick={() => setShowModal(true)}>
                    Add Your Ratings
                </button>
                </div>
            </div>
            {showModal && (
                <AddReviewModalDialog
                    onSaveClick={(reviewType) => onSaveClick(reviewType)}
                    onOutsideClick={() => setShowModal(false)}
                />
            )}

        </div>
    )
}

RatingsOverview.propTypes = {
    wine: PropTypes.object.isRequired,
};

export default RatingsOverview;