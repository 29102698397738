import React, {useState} from "react";
import PropTypes from "prop-types";
import "./HoldingItem.css";
import api from "./api";
import * as firebase from 'firebase/app'
import ModalDialog from "./ModalDialog";
import {useHistory} from "react-router-dom";

function DeleteHoldingItemModalDialog(props) {

    return (
        <ModalDialog
            title="Are you sure you would like to delete this holding?"
            buttonText="Delete holding"
            buttonAction={props.onDeleteClick}
            outsideClickAction={props.onCloseClick}>
        </ModalDialog>
    )
}

DeleteHoldingItemModalDialog.propTypes = {
    onDeleteClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
};

function EditHoldingItemModalDialog(props) {

    function onSaveClick() {
        const newFieldPath = document.getElementById("new-string");
        props.onSaveClick(newFieldPath.value);
    }

    return (
        <ModalDialog
            title={props.headingText}
            buttonText="Save changes"
            buttonAction={onSaveClick}
            outsideClickAction={props.onCloseClick}>
            <input
                id="new-string"
                type="string"
                defaultValue={props.defaultText}>
            </input>
        </ModalDialog>
    )
}

EditHoldingItemModalDialog.propTypes = {
    defaultText: PropTypes.string.isRequired,
    headingText: PropTypes.string.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
};


function AddTypeVarietalDialog(props) {
    const[varietal,setVarietal] = useState(null);
    const [wineType, setWineType] = useState(null);

    function onSaveClick() {
        props.onSaveClick(wineType,varietal);
    }

    return (
        <ModalDialog
            title="Fixes"
            buttonText="Save changes"
            buttonAction={onSaveClick}
            outsideClickAction={props.onCloseClick}>
            <div className="row">
                <div className="label">What is the varietal of the wine?</div>
            </div>
            <div className="multi-select-row">
                <button type="button"
                        className={`btn ${varietal === "Cabernet Franc" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Cabernet Franc")}>
                    Cabernet Franc
                </button>
                <button type="button"
                        className={`btn ${varietal === "Cabernet Sauvignon" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Cabernet Sauvignon")}>
                    Cabernet Sauvignon
                </button>
                <button type="button"
                        className={`btn ${varietal === "Chardonnay" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Chardonnay")}>
                    Chardonnay
                </button>
                <button type="button"
                        className={`btn ${varietal === "Malbec" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Malbec")}>
                    Malbec
                </button>
                <button type="button"
                        className={`btn ${varietal === "Meritage" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Meritage")}>
                    Meritage
                </button>
                <button type="button"
                        className={`btn ${varietal === "Merlot" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Merlot")}>
                    Merlot
                </button>
                <button type="button"
                        className={`btn ${varietal === "Petite Sirah" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Petite Sirah")}>
                    Petite Syrah
                </button>
                <button type="button"
                        className={`btn ${varietal === "Pinot Noir" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Pinot Noir")}>
                    Pinot Noir
                </button>
                <button type="button"
                        className={`btn ${varietal === "Riesling" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Riesling")}>
                    Riesling
                </button>
                <button type="button"
                        className={`btn ${varietal === "Rose" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Rose")}>
                    Rose
                </button>
                <button type="button"
                        className={`btn ${varietal === "Sauvignon Blanc" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Sauvignon Blanc")}>
                    Sauvignon Blanc
                </button>
                <button type="button"
                        className={`btn ${varietal === "Syrah" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Syrah")}>
                    Syrah
                </button>
                <button type="button"
                        className={`btn ${varietal === "Viognier" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Viognier")}>
                    Viognier
                </button>
                <button type="button"
                        className={`btn ${varietal === "Zinfandel" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setVarietal("Zinfandel")}>
                    Zinfandel
                </button>
            </div>
            <div className="row">
                <div className="label">What is the type of the wine?</div>
            </div>
            <div className="multi-select-row">
                <button type="button"
                        className={`btn ${wineType === "White" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setWineType("White")}>
                    White
                </button>
                <button type="button"
                        className={`btn ${wineType === "Rose" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setWineType("Rose")}>
                    Rose
                </button>
                <button type="button"
                        className={`btn ${wineType === "Red" ? 'btn-info' : 'btn-outline-info'}`}
                        onClick={() => setWineType("Red")}>
                    Red
                </button>
            </div>
        </ModalDialog>
    )
}

AddTypeVarietalDialog.propTypes = {
    onSaveClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
};


function HoldingItem(props) {

    const taxRate = 1.085;
    const [itemCount, setItemCount] = useState(props.holding.numBottles);
    const [consumedCount, setConsumedCount] = useState(props.holding.numConsumed ? props.holding.numConsumed : 0);
    const [errorUpdatingCount, setErrorUpdatingCount] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    //NOTE: isSource below and throughout this class actually corresponds to the Note for the holding
    const [editFields, setEditFields] = useState({showEdit: false, isSource: false, isCellarLoc: false});
    const [addTypeAndVarietal, setAddTypeAndVarietal] = useState(false);

    let history=useHistory();


    function incrementCount(diff) {
        const originalCount = itemCount;
        setItemCount(itemCount + diff);
        api()
            .collection('users')
            .doc(props.userId)
            .collection('holdings')
            .doc(props.holding.holdingId)
            .set({
                numBottles: firebase.firestore.FieldValue.increment(diff)
            }, {
                merge: true
            })
            .catch(error => {
                setErrorUpdatingCount(true)
                setItemCount(originalCount)
                console.error(error.message);
            })
    }

    function incrementConsumedCount(diff) {
        const originalCount = consumedCount;
        const holdingsIncrement = -1*diff;
        setConsumedCount(consumedCount + diff);
        api()
            .collection('users')
            .doc(props.userId)
            .collection('holdings')
            .doc(props.holding.holdingId)
            .set({
                numConsumed: firebase.firestore.FieldValue.increment(diff)
            }, {
                merge: true
            })
            .then(() => incrementCount(holdingsIncrement))
            .catch(error => {
                setErrorUpdatingCount(true);
                setConsumedCount(originalCount);
                console.error(error.message);
            })
    }

    function getCellarText(inCellar) {
        // if (inCellar === false) {
        //     return "No"
        // } else if (inCellar === true) {
        //     return "Yes"
        // } else {
        //     return "Unknown"
        // }
        if (inCellar) {
            return inCellar
        } else {
            return "Unknown"
        }
    }

    function getTotalBottlePrice(casePrice, dbShippingCost, dbBottlesPerCase) {
        let shippingCost =  dbShippingCost ?  dbShippingCost : 29;
        let bottlesPerCase = dbBottlesPerCase ? dbBottlesPerCase : 12;
        const bottlePrice = (((casePrice*taxRate)+shippingCost)/bottlesPerCase).toFixed(2);
        return parseFloat(bottlePrice);
    }

    function getTotalValue(casePrice, dbShippingCost, dbBottlesPerCase) {
        let shippingCost =  dbShippingCost ?  dbShippingCost : 29;
        let bottlesPerCase = dbBottlesPerCase ? dbBottlesPerCase : 12;
        const bottlePrice = (((casePrice*taxRate)+shippingCost)/bottlesPerCase);
        const totValue = (bottlePrice*itemCount).toFixed(2);
        return parseFloat(totValue);
    }

    // function getInCellar() {
    //     const dateParts = props.holding.shipDate.split('-');
    //     const dateShipped = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    //     const todayDate = new Date();
    //     if (todayDate > dateShipped) {
    //         return "Yes"
    //     } else {
    //         return "No"
    //     }
    // }

    function goToDetail() {
        history.push(`/${props.holding.wineNumber}`);
    }

    function onDeleteClick() {
        api().collection("users")
            .doc(props.userId)
            .collection('holdings')
            .doc(props.holding.holdingId)
            .delete()
            .catch(() => {
                //setError if cleaning up
            })
            .finally(()=>{
                setShowDelete(false)
            })
    }


    function onSaveTypeClick(wineType, varietal) {

        api().collection("users")
            .doc(props.userId)
            .collection('holdings')
            .doc(props.holding.holdingId)
            .set({type: wineType,
                  varietal: varietal}, { merge: true })
            .catch(() => {
                //setError if cleaning up
            })
            .finally(()=>{
                setAddTypeAndVarietal(false);
            })
    }

    function onSaveClick(value) {
        let updateText = null;
        if (editFields.isSource) {
            props.holding.source = value;
            updateText = {
                "source": value
        }} else {
            props.holding.cellarLocation = value;
            updateText = {
                "cellarLocation": value
        }}

        api().collection("users")
            .doc(props.userId)
            .collection('holdings')
            .doc(props.holding.holdingId)
            .set(updateText, { merge: true })
            .catch(() => {
                //setError if cleaning up
            })
            .finally(()=>{
                setEditFields({showEdit: false, isCellarLoc: false, isSource: false})
            })
    }

    return (
        <div id={props.holding.holdingId}
             className="HoldingItem card">
            <div className="row text-center">
                <div className="col">
                    <h5>OG</h5>
                    <h6>{props.holding.wineNumber}</h6>
                </div>
                <div className="col">
                    <h5>Wine</h5>
                    <h6>{props.holding.wineName}</h6>
                </div>
                <div className="col">
                    {/*<h5>{itemCount}</h5>*/}
                    <h5>Holdings</h5>
                    <h6>{itemCount}</h6>
                    <span className="vote-arrow"
                          onClick={() => incrementCount(1)}>
                            ⬆️
                        </span>
                    <span className="vote-arrow"
                          onClick={() => {if (itemCount > 0) incrementCount(-1)}}>
                            ⬇️
                        </span>
                </div>
                <div className="col">
                    {/*<h5>{itemCount}</h5>*/}
                    <h5>Consumed</h5>
                    <h6>{consumedCount}</h6>
                    <span className="vote-arrow"
                          onClick={() => incrementConsumedCount(1)}>
                            ⬆️
                        </span>
                    <span className="vote-arrow"
                          onClick={() => {if (consumedCount > 0) incrementConsumedCount(-1)}}>
                            ⬇️
                        </span>
                </div>
                <div className="col">
                    <h5>Note</h5>
                    <h6 className="changeable" onClick={() => setEditFields({showEdit:true, isSource: true, isCellarLoc: false})}
                    >{props.holding.source? props.holding.source : "None"}</h6>
                </div>
                {/*<div className="col">*/}
                {/*    <h5>In Cellar?</h5>*/}
                {/*    <h6>{getInCellar()}</h6>*/}
                {/*</div>*/}
                {/*{props.holding.shipDate && (<div className="col">*/}
                {/*        <h5>Ship Date</h5>*/}
                {/*        <h6>{props.holding.shipDate}</h6>*/}
                {/*    </div>)}*/}
                <div className="col">
                    <h5>Cellar Location</h5>
                    {/*<h6>{getCellarText(props.holding.inCellar)}</h6>*/}
                    <h6 className="changeable" onClick={() => setEditFields({showEdit: true, isSource: false, isCellarLoc: true})}
                    >{getCellarText(props.holding.cellarLocation)}</h6>
                </div>
                <div className="col">
                    <h5>Price per Bottle</h5>
                    <h6>{getTotalBottlePrice(props.holding.casePrice,props.holding.shippingCost,props.holding.bottlesPerCase)}</h6>
                </div>
                <div className="col">
                    <h5>Total value</h5>
                    <h6>{getTotalValue(props.holding.casePrice,props.holding.shippingCost,props.holding.bottlesPerCase)}</h6>
                </div>
                <div className="col">
                    <button className="primary-button" onClick={() => setShowDelete(true)}>
                        DELETE HOLDING
                    </button>
                    <p></p>
                    <button className="primary-button" onClick={() => goToDetail()}>
                        GO TO DETAIL
                    </button>
                    {/*<button>*/}
                    {/*    <Link to={`/${props.holding.wineNumber}`} className="card-item-title-link">*/}
                    {/*        GO TO DETAIL*/}
                    {/*    </Link>*/}
                    {/*</button>*/}
                </div>
                {/*<button onClick={() => setAddTypeAndVarietal(true)}>*/}
                {/*    Add Type/Varietal*/}
                {/*</button>*/}
            </div>

            {addTypeAndVarietal && (
                <AddTypeVarietalDialog onSaveClick={(wineType,varietal) => onSaveTypeClick(wineType, varietal)}
                                        onCloseClick={()=> setAddTypeAndVarietal(false)}>
                </AddTypeVarietalDialog>
            )}

            {showDelete && <DeleteHoldingItemModalDialog onDeleteClick={()=> onDeleteClick()}
                                                         onCloseClick={() => setShowDelete(false)}/>}
            
            {(editFields.showEdit && editFields.isSource) && (
                <EditHoldingItemModalDialog defaultText={props.holding.source}
                                            headingText={"Enter updated note (related to holding status NOT tasting notes or ratings)"}
                                            onSaveClick={(value) => onSaveClick(value)}
                                            onCloseClick={() => setEditFields({showEdit: false, isCellarLoc: false, isSource: false})}/>
            )}

            {(editFields.showEdit && editFields.isCellarLoc) && (
                <EditHoldingItemModalDialog defaultText={props.holding.cellarLocation}
                                            headingText="Enter updated cellar location"
                                            onSaveClick={(value)=>onSaveClick(value)}
                                            onCloseClick={() => setEditFields({showEdit: false, isCellarLoc: false, isSource: false})}
                                            />
            )}

            {errorUpdatingCount && (
                <ModalDialog
                    title="Problem updating count"
                    body={
                        "Sorry about that! It looks like there was an error "
                        + "updating your holdings. Please make sure you're "
                        + "connected to the Internet and try again. If there's "
                        + "still a problem, please contact us!"
                    }
                    buttonText="OK"
                    buttonAction={() => setErrorUpdatingCount(false)}
                    outsideClickAction={() => setErrorUpdatingCount(false)} />
            )}
        </div>
    )
}
HoldingItem.propTypes = {
    holding: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
};

export default HoldingItem;