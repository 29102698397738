import React, {useEffect} from "react";
import "./About.css"
import venmoQR from "./VenmoQR.png"
import {logExternalLink, logPageView} from "./analytics";
import LinkOut from "./LinkOut";
import {Link} from "react-router-dom";

function About() {

    useEffect(() => {
        logPageView("about");
    },[]);

    return <div className="About">
        <div className="text">
            <h5>What is this?</h5>
            <p>
                I built deNégociants in an effort to help myself and others who are trying to keep track of a lot of
                different de Négoce wines coming into our cellars.  It was difficult to easily find information on
                individual wines from the long online threads
                that discuss all the de Negoce wines.  Plus, a spreadsheet really couldn't display all the info I wanted
                to access in an easy to view manner.  It seemed a lot of people on the <LinkOut
                onClick={()=>logExternalLink("Wine Berserkers")}
                url="https://www.wineberserkers.com/t/what-de-negoce-wine-are-you-drinking-tonight/164452"
                text="Wine Berserker thread"/> were
                having similar issues, so I decided to make something that might help.
            </p>
        </div>
        <div className="text">
            <h5>Thanks for doing this.  How can I help?</h5>
            <p>
                With so many views occurring on the site now, the costs of running it have gone up (too many reads, for you techies out there),
                so if you think it brings value to you and would like to make a donation to its upkeep, that would be much appreciated.
                My Venmo link is below.
                Also, I finally added a referral link.  If you are going to make your first purchase and use my link,
                it will give you $25 off your order and I get a $25 wine credit.  It's a no lose way to contribute to the upkeep
                of the site.  You can either type it in (http://dengoce.refr.cc/talamanca149)
                or <br/>
                <LinkOut
                    onClick={()=>logExternalLink("DeNegoce Referral Link")}
                    url="http://dengoce.refr.cc/talamanca149"
                    text="Go to de Negoce Now"/>
            </p>
        </div>
        <div>
            <h5>@L-Cook</h5>
            <img src={venmoQR} />
            <p>Venmo for de Négociants</p>
        </div>
        <div className="text">
            <h5>Are you affiliated with the owners or web properties of de Negoce?</h5>
            <p>
                Not at all.  This is just starting because of a need that arose due to a lot of people buying large quantities
                of de Negoce wines, and I thought the name was both different enough to not be confusing and cute.
            </p>
        </div>
        <div className="text">
            <h5>Do I need an account to use this site?</h5>
            <p>
                Only if you want to use the 'Holdings' functionality.  A user account is needed for that to keep your individual wine list
                separated and secure.  Other than that there is no need to set up an account to use the site.
            </p>
        </div>
        <div className="text">
            <h5>What is 'Holdings'?</h5>
            <p>
                If you create an account, it opens up the option to use the Holdings functionality.  This allows you to easily keep an inventory
                of your de Negoce wine holdings, including number of each bottle purchased/consumed, notes, and cellar location.  You can also easily search
                your inventory by wine type or wine number.

            </p>
        </div>

    </div>

}

export default About;
